import Vue from 'vue'
import ConfigMixin from '@/mixins/Config.js'
import RootUserMixin from 'GroomyRoot/mixins/User.js'
import Constants from 'Constants'

export default Vue.util.mergeOptions(RootUserMixin, {
	data: () => ({
		need_valid_mail: false
	}),
    methods: {
        checkLogin: async function(email, password) {           
            return this.$request.request_post_api("UserMixin::checkLogin", Constants.USER_LOGIN, {
                    email: email,
                    password: password
                }, false, { sync:false })
                .then(response => {
                    if(response.code_retour === 0) {
                        const data = response.retour
                        this.persistUserSession(data.token, data.licence_key, data.licence_username, data.user_id, data.valid_user, data.valid_mail, data.lang)
                        this.persistUserLicence(data)
						this.need_valid_mail = data.valid_mail
                    }

                    return response
				})
		},
		
		redirectPostLogin(redirect='') {
			if(this.need_valid_mail) {
				this.$router.push({
					name: 'Loading',
					query: {
						redirect
					}
				})
			}
			else {
				this.$router.push({ name: 'EmailValidation' })
			}
        },
        
        async pesistUserCaConfig() {
            return this.$request.request_get_api("UserMixin::pesistUserCaConfig", Constants.USER_CA_DETAILS)
                .then(res => {
                    if(!res || !res.retour) return
                    this.setConfig("user_rs", res.retour.user_rs)
                    this.setConfig("user_mail", res.retour.user_mail)
                    this.setConfig("user_country", res.retour.user_country)
                    this.setConfig("user_mail_encoded", res.retour.user_mail_encoded)
                    this.setConfig("default_redirect", res.retour.redirect)
                    this.setConfig("user_ca_mail", res.retour.user_ca_mail)
                    this.setConfig("contract_sire_obligatory", res.retour.contract_sire_obligatory)

                    this.setConfig("configs_horse_sexes", res.retour.configs_horse.sexes)
                    this.setConfig("configs_horse_races", res.retour.configs_horse.races)

                    const tabs = JSON.stringify(res.retour.tabs)
                    this.setConfig("authorized_tabs", this.$cryptaes.encrypt(tabs))

                    const config = JSON.stringify(res.retour.config)
                    this.setConfig("ca_config", config)
            })
        },

        checkUserValid() {
            return ConfigMixin.methods.getConfig('valid_mail') || false
        },
        getUserMail() {
            return ConfigMixin.methods.getConfig('user_mail') || null
        },
        getUserMailEncoded() {
            return ConfigMixin.methods.getConfig('user_mail_encoded') || null
        },
        getUserCAMail() {
            return ConfigMixin.methods.getConfig('user_ca_mail') || null
        },
        defaultCaRedirect() {
            return ConfigMixin.methods.getConfig('default_redirect') || 'horseListe'
        },
        getConfigHorseSexes() {
            const config = ConfigMixin.methods.getConfig('configs_horse_sexes')
            if(config) {
                return config.split(',')
            }
            return []
        },
        getConfigHorseRaces() {
            const config = ConfigMixin.methods.getConfig('configs_horse_races')
            if(config) {
                return config.split(',')
            }
            return []
        },

        async submitAutoLog(email, token) {
            if(this.checkLocal()) {
                await this.delogUser(false, false)
            }

            return this.$request.request_post_api("UserMixin::submitAutoLog", Constants.USER_AUTOLOGIN, {
                email: email,
                signature: token
            }, false, { sync:false })
            .then(response => {
                if(response.code_retour === 0) {
                    const data = response.retour
                    this.persistUserSession(data.token, data.licence_key, data.licence_username, data.user_id, data.valid_user, data.valid_mail, data.lang)
                    this.persistUserLicence(data)
                    this.need_valid_mail = data.valid_mail
                }
            })
        },
        async getRedirectUrl() {

            return this.$request.request_get_api("UserMixin::getRedirectUrl", Constants.USER_REDIRECT_URL)
        },

        delogUser: async function(keep_config=false, redirect=true) {
            await this.$request.request_get_api("UserMixin::delogUser", Constants.USER_LOGOUT)
                .then(async res => {
                    this.deleteLocalData(redirect)
                })
                .catch(e => {
                    this.deleteLocalData(redirect)
                })
        },

        deleteLocalData(redirect) {
            this.rmConfig()
            this.rmConfigCache()
            this.request.reset_header()

            this.$router.user_logged = false

            // On ne redirige que si le delog ne vient pas du switch licence
            if(this.$route.name != "Home" && redirect) {
                this.$router.push("/")
            }
        },

        sendAutolog: async function(mail, lang) {
            const url = Constants.USER_SEND_AUTOLOG
            return await this.$request.request_post_api("UserMixin::sendAutolog", url, {mail, lang}, false)
        },

        updatePassword(password) {
            const url = Constants.PASSWORD_URL
            const params = {
                password
            }

            return this.$request.request_post_api("UserMixin::updatePassword", url, params, false)
            .catch(this.$request.parseError)
        },
    }
})
