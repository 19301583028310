import { EventBus } from 'EventBus';
import User from '@/mixins/User.js'

var Navigation = {
    mixins: [User],
    data() {
        return {
			need_events: true,
            state: '',
            state_index: 0,
            componentTitle: '',
			defaultComponentTitle: '',
			componentName: ''
        }
    },
	methods: {
        // Pour cette méthode, il faut que le nom du composant/vue dans vue-router soit le même que celui de le l'état le désignant dans la liste des états
        set_actual_state_from_route(route_name) {
            const states = Object.keys(this.tab_states)
            this.state_index = states.indexOf(route_name)
            this.state = states[this.state_index]

            if(this.state && this.tab_states[this.state] != '') {
                this.componentTitle = this.getTrad(this.tab_states[this.state])
            }
            else {
                this.componentTitle = this.getTrad(this.defaultComponentTitle)
            }
        },

        set_actual_status() {
            const states = Object.keys(this.tab_states)
            this.state = states[this.state_index]
            this.componentTitle = this.getTrad(this.tab_states[this.state])
        },
        set_next_state() {
            this.state_index += 1
            this.set_actual_status()
        },
        set_last_state() {
            if(this.state_index > 0) {
                this.state_index -= 1
                this.set_actual_status()
            }
        },

        goBack() {
            window.history.length > 2
            ? this.$router.go(-1)
            : this.$router.push('/')
        },

        enableEvents() {
			this.componentName = this.$route.name
			
            if(this.events_tab) {
                for (let event_name in this.events_tab) {
                    EventBus.$on(event_name, this.events_tab[event_name])
                }
            }
        },

        destroyEvents() {
            if(this.events_tab) {
                for (let event_name in this.events_tab) {
                    EventBus.$off(event_name, this.events_tab[event_name])
                }
            }
        },

        /* tab => tableau de lignes séléctionnées */
        /* param_name => donnée à en extraire pour construire la liste de params */
        constructRouteParams(tab, param_name) {
            const params = this.getArrayObjProperty(tab, param_name)
            return params.join(',')
        },

        // TOOLS
        constructHorseRouteParams(horses, params={}) {
            if(horses.length > 0) {
                const horse_ids = this.constructRouteParams(horses, 'horse_id')
                params.horse_id = horse_ids
            }
            else {
                const horse_id = this.$route.params.horse_id
                if(horse_id) {
                    params.horse_id = horse_id
                }
            }
            return params
        }
	},
    computed: {
        fromComponent() {
            return this.$route.name
        }
    },

    created() {
		if(this.need_events) this.enableEvents()
    },
    mounted() {
        if(this.actual_tab && this.swiper_tabs) {
            const temp = this.swiper_tabs.filter(tab => tab.href == this.actual_tab)
            if(temp.length > 0 && this.$route.name != temp[0].href && this.$options.name == this.$route.name) {
                this.$router.push({name: temp[0].href})
            }
        }
    },
    beforeDestroy() {
        if(this.need_events) this.destroyEvents()
    }
}

export default Navigation
