/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from "./router"

/* PLUGINS */
import BootstrapVue from 'bootstrap-vue'
import AsyncComputed from 'vue-async-computed'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMask from 'di-vue-mask'
import VueMeta from 'vue-meta'
import VueLazyLoad from 'vue-lazyload'
import InfiniteLoading from 'vue-infinite-loading'

/* PLUGINS MAISON */
import Common from './assets/js/common.js'
import { getSubDomainName } from './assets/js/common.js'
import i18n, { getNavigatorLang, loadI18nPlugin } from 'GroomyRoot/assets/js/i18n.js'
import Constants from 'Constants'
import Request from './assets/js/requests.js'
import CryptAes from 'GroomyRoot/assets/js/cryptaes.js'
import VuexStore from './store'
import 'GroomyRoot/assets/js/rollbar.js'
import './assets/js/theme.js'

/* MIXINS */
import Cache from "@/mixins/Cache.js"
import Config from "@/mixins/Config.js"
import Permissions from "@/mixins/Permissions.js"
import Toasts from "@/mixins/Toasts.js"
import Tools from "@/mixins/Tools.js"

/* DIRECTIVES */
import OnlineOnly from '@/directives/OnlineOnly'

/* COMPOSANTS */
import ESelect from 'GroomyRoot/components/Utils/ESelect'
import EDatepicker from 'GroomyRoot/components/Utils/EDatepicker'
import EEditor from 'GroomyRoot/components/Utils/EEditor'
import ECarousel from 'GroomyRoot/components/Utils/ECarousel'
import BackButton from 'GroomyRoot/components/Buttons/BackButton'
import GroomyLogo from '@/components/Logos/LogoMenu_111'
import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'GroomyRoot/assets/js/icons.js'


/* Démarrage de la webapp */
var common = null
var connectivity = true

const init = async () => {
    Vue.use(BootstrapVue)
    Vue.use(VueAxios, axios)
    Vue.use(AsyncComputed)
    Vue.use(VueMask)
    Vue.use(VueMeta)
    Vue.use(VueLazyLoad)
    Vue.use(InfiniteLoading)

	Vue.mixin(Cache)
	Vue.mixin(Config)
	Vue.mixin(Permissions)
	Vue.mixin(Toasts)
	Vue.mixin(Tools)

    Vue.directive('e-online-only', OnlineOnly)

	Vue.component(FontAwesomeIcon.name, FontAwesomeIcon)
	Vue.component('e-select', ESelect)
	Vue.component('back-button', BackButton)
	Vue.component('e-datepicker', EDatepicker)
	Vue.component('groomy-logo', GroomyLogo)
	Vue.component('e-carousel', ECarousel)
	Vue.component('shutter-panel', ShutterPanel)
	Vue.component('e-editor', EEditor)

    common = new Common()
	initApp()
}

function initApp() {
	// On set des données accessibles via l'interface de vue
	Vue.prototype.$common = common
	Vue.prototype.$connectivity = connectivity
	Vue.prototype.$request = new Request()
	Vue.prototype.$request.set_headers()
	Vue.prototype.$cache = {}
	Vue.prototype.$cryptaes = new CryptAes()
    Vue.prototype.$connectivity = false

	initAppConfigHeader()

	// I18N
	let configLang = Config.methods.getConfig("lang")
	if (!configLang) {
		configLang = getNavigatorLang()
	}
	loadI18nPlugin(configLang)

	// On a toutes les données de base
	// On peut init notre objet vue
	// Dans le scope de vuejs (sur une vue, ou un composant par ex), on aura ces données
	new Vue({
		el: '#app',
		router,
		i18n,
		store: VuexStore,
		connectivity: false,
		cache: {}, // objet visant à garder de côté des données pouvant être mises en cache (style données statiques comme horse_race)
		common: common,
		render: h => h(App)
	})
}

function initAppConfigHeader() {
    if(Config.methods.getConfig("licence_key") && Config.methods.getConfig("token")){
		Constants.USER_LICENCE_KEY = Config.methods.getConfig("licence_key")
		Vue.prototype.$request.set_headers(Config.methods.getConfig("token"))
		Vue.prototype.$connectivity = true
	}
}

function initCustoms() {
    const domain_name = getSubDomainName()
    
    if(!domain_name) return 
    const icons_sizes = [120, 152, 180]

    const title = document.createElement('meta')
    title.setAttribute('name', 'apple-mobile-web-app-title')
    title.setAttribute('content', Vue.options.filters.multiCapitalize(domain_name))
    document.getElementsByTagName('head')[0].appendChild(title)

    icons_sizes.forEach(size => {
        const icon = document.createElement('link')
        const href_pattern = `img/ios-icons/${domain_name}/apple-icon-${size}x${size}.png`

        icon.setAttribute('rel', 'apple-touch-icon')
        icon.setAttribute('href', href_pattern)
        document.getElementsByTagName('head')[0].appendChild(icon)
    })

    const color_tab = {
    	'groomy': '#DC1258',
    	'croissance-trot': '#2AA31C',
    	'equideclic': '#1c57a5',
    	'eric-levallois': '#cd9557',
    	'etalons-dressage': '#1d4777',
    	'genesdiffusion': '#199DE0',
    	'gfe': '#BB996C',
    	'huntervalley': '#cd9557',
    	'monceaux': '#EB7273',
    	'pony-stallion': '#951a21',
		'france-etalon': '#951a21',
    }
    const color = color_tab.hasOwnProperty(domain_name) ? color_tab[domain_name] : 'groomy'

    document.querySelectorAll('[name="theme-color"]')[0].setAttribute('content', color)
    document.querySelectorAll('[name="apple-mobile-web-app-status-bar-style"]')[0].setAttribute('content', color)
    document.querySelectorAll('[rel="mask-icon"]')[0].setAttribute('color', color)
    document.querySelectorAll('[name="msapplication-TileColor"]')[0].setAttribute('content', color)
}

/* Extensions */
Vue.filter('uppercase', function (value) {
	if (!value) return ''
	return value.toString().toUpperCase()
})

Vue.filter('lowercase', function (value) {
	if (!value) return ''
	return value.toString().toLowerCase()
})

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('multiCapitalize', function(value) {
	if (!value) return ''
	value = value.toString().toLowerCase()

	return value.split(' ')
		.map(str => str.charAt(0).toUpperCase() + str.slice(1))
		.join(' ')
})

init()
initCustoms()