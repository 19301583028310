<template>
	<div>
		<swiper :options="swiperOption">
            <swiper-slide v-for="(media, index) in medias" :key="`media_carousel_${uuid}_${index}`">
                <template v-if="isImgRendable(media)">
					<div class="container-note note-image">
						<img :src="sourceFile(media)" />
					</div>
				</template>
				<template v-if="isVideoRendable(media)">
					<div class="container-note note-video">
						<span v-if="working" class="d-flex justify-content-center">
							<font-awesome-icon :icon="['fal', 'spinner']" spin />
						</span>
						<video v-else controls :src="sourceFile(media)"></video>
					</div>
				</template>
				<template v-if="isAudioRendable(media)">
					<div class="container-note note-audio">
						<audio :src="sourceFile(media)" controls></audio>
					</div>
				</template>
				<template v-else-if="isIframeRendable(media)">
					<div class="container-note note-iframe">
						<iframe :src="`${sourceFile(media)}#zoom=25&view=Fit`" /> 
					</div>
				</template>
				<template v-if="isEmbeddRendable(media)">
					<div class="container-note note-video">
						<iframe :src="sourceFile(media)" frameborder="0" allow="accelerometer; encrypted-media; gyroscope" allowfullscreen />
					</div>
				</template>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <!-- <div class="swiper-prev swiper-control" slot="button-prev">
            	<font-awesome-icon :icon="['far', 'angle-left']"  />
            </div>
            <div class="swiper-next swiper-control" slot="button-next">
  				<font-awesome-icon :icon="['far', 'angle-right']" />
  			</div> -->
		</swiper>
    </div>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import Notes from '@/mixins/Notes.js'
	import 'swiper/dist/css/swiper.css'
	import { swiper, swiperSlide } from 'vue-awesome-swiper'

	export default {
        props: {
            medias: { type : Array, default: () => [] }
        },
        mixins: [Notes],
		data () {
			return {
                uuid: '',
				swiperOption: {
					slidesPerView: 'auto',
					spaceBetween: 0,
					preventClicks: false,
					preventClicksPropagation: false,
					pagination: {
						el: '.swiper-pagination',
					},
					navigation: {
						nextEl: '.swiper-next',
						prevEl: '.swiper-prev',
					},
				}
			}
        },
        created() {
            this.uuid = Common.getRandomString(5)
        },
        components: {
            swiper,
            swiperSlide
        }
	}
</script>
