import Vue from 'vue'
import { Paragraph } from 'tiptap'

export default class ContentBlock extends Paragraph {

	get name() {
		return 'content_block'
	}

	get defaultOptions() {
		return {
			blockTag: 'div',
			blockClass: 'tiptap-block',
		}
	}

	/**
	 * @see https://prosemirror.net/docs/ref/#model.Schema
	 */
	get schema() {
		return {
			content: 'block*',
			group: 'block',
			selectable: false,
			atom: false,
			draggable: false,
			code: false,
			defining: true,
			isolating: false,
			attrs: {
				name: {},
				title: {},
			},
			marks: '_',
			parseDOM: [
				{
					tag: `${this.options.blockTag}.${this.options.blockClass}[data-block-name]`,
					getAttrs(node) {
						return {
							name: node.getAttribute('data-block-name'),
							title: node.getAttribute('data-block-title')
						}
					}
				},
			],
			toDOM: (node) => {
				return [
					this.options.blockTag,
					{
						'class': this.options.blockClass,
						'data-block-name': node.attrs.name,
						'data-block-title': node.attrs.title,
					},
					["div", 0]
				]
			}
		}
	}

	commands({ type, schema }) {
		return (attrs) => {
			return (state, dispatch) => {
				const content = schema.nodes.paragraph.create()
				const node = type.create(attrs, content)
				const transaction = state.tr.replaceSelectionWith(node)
				dispatch(transaction)
			}
		}
	}
}
