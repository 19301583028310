import { library } from '@fortawesome/fontawesome-svg-core'

// Regular
import {
    faAlignJustify as farAlignJustify,
    faArrowDown as farArrowDown,
    faArrowUp as farArrowUp,
    faArrowRight as farArrowRight,
    faBell as farBell,
    faCalculator as farCalculator,
    faCalendar as farCalendar,
    faCalendarAlt as farCalendarAlt,
    faCheck as farCheck,
    faCheckCircle as farCheckCircle,
    faCheckDouble as farCheckDouble,
    faCreditCard as farCreditCard,
    faFile as farFile,
    faFileInvoice as farFileInvoice,
    faFileMedicalAlt as farFileMedicalAlt,
    faFilePdf as farFilePdf,
    faGripLines as farGripLines,
    faGlobe as farGlobe,
    faHandsUsd as farHandsUsd,
    faHistory as farHistory,
    faLayerPlus as farLayerPlus,
    faListUl as farListUl,
    faLocation as farLocation,
    faMapMarkerAlt as farMapMarkerAlt,
    faMapMarkerCheck as farMapMarkerCheck,
    faMoneyBillAlt as farMoneyBillAlt,
    faMoneyCheckEditAlt as farMoneyCheckEditAlt,
    faPaperPlane as farPaperPlane,
    faPen as farPen,
    faPencilAlt as farPencilAlt,
    faPlus as farPlus,
    faPrint as farPrint,
    faQuestionSquare as farQuestionSquare,
    faReceipt as farReceipt,
    faRepeatAlt as farRepeatAlt,
    faRoute as farRoute,
    faSave as farSave,
    faServer as farServer,
    faSpinner as farSpinner,
    faSquare as farSquare,
    faStickyNote as farStickyNote,
    faTable as farTable,
    faTasks as farTasks,
    faTimes as farTimes,
    faTimesCircle as farTimesCircle,
    faTrash as farTrash,
    faTrashAlt as farTrashAlt,
    faUser as farUser,
    faSignature as farSignature,
    faBold as farBold,
    faItalic as farItalic,
    faStrikethrough as farStrikethrough,
    faUnderline as farUnderline,
    faH1 as farH1,
    faH2 as farH2,
    faH3 as farH3,
    faH4 as farH4,
    faListOl as farListOl,
    faHorizontalRule as farHorizontalRule,
    faUndo as farUndo,
    faRedo as farRedo,
    faCamera as farCamera,
    faBracketsCurly as farBracketsCurly,
    faInfinity as farInfinity,
    faHorse as farHorse,
    faClone as farClone,
    faCogs as farCogs,
    faCalculatorAlt as farCalculatorAlt,
    faCopy as farCopy,
    faAngleLeft as farAngleLeft,
    faAngleRight as farAngleRight,
    faSort as farSort,
    faRandom as farRandom,
    faWaveformPath as farWaveformPath,
    faNotesMedical as farNotesMedical,
    faArchive as farArchive,
    faExchangeAlt as farExchangeAlt,
    faSearch as farSearch,
    faStream as farStream,
    faInfoCircle as farInfoCircle,
    faInfo as farInfo,
    faAngleDown as farAngleDown,
    faUserLock as farUserLock,
    faMoneyCheckEdit as farMoneyCheckEdit,
    faAngleUp as farAngleUp,
    faUndoAlt as farUndoAlt,
    faChartArea as farChartArea,
    faEraser as farEraser,
    faLock as farLock,
    faFileImport as farFileImport,
    faLockOpen as farLockOpen,
    faUpload as farUpload,
    faPercent as farPercent,
    faDollyEmpty as farDollyEmpty
} from '@fortawesome/pro-regular-svg-icons'

// Light
import {
    faAngleUp as falAngleUp,
    faAngleDown as falAngleDown,
    faAngleRight as falAngleRight,
    faArrowLeft as falArrowLeft,
	faBaby as falBaby,
    faBallotCheck as falBallotCheck,
    faBars as falBars,
    faBell as falBell,
    faBinoculars as falBinoculars,
    faBracketsCurly as falBracketsCurly,
    faCalculatorAlt as falCalculatorAlt,
    faCalendar as falCalendar,
    faCalendarAlt as falCalendarAlt,
    faCalendarWeek as falCalendarWeek,
    faCamera as falCamera,
    faCaretDown as falCaretDown,
    faChartPie as falChartPie,
    faChartPieAlt as falChartPieAlt,
    faCheck as falCheck,
    faCheckCircle as falCheckCircle,
    faChevronCircleRight as falChevronCircleRight,
    faCircleNotch as falCircleNotch,
    faCrutch as falCrutch,
    faCogs as falCogs,
    faCube as falCube,
    faDatabase as falDatabase,
    faDesktop as falDesktop,
    faDoorOpen as falDoorOpen,
    faDownload as falDownload,
    faEdit as falEdit,
    faEnvelope as falEnvelope,
	faExclamationTriangle as falExclamationTriangle,
	faExternalLinkSquareAlt as falExternalLinkSquareAlt,
    faEyeSlash as falEyeSlash,
    faFarm as falFarm,
    faFlagCheckered as falFlagCheckered,
    faFileAlt as falFileAlt,
    faFileChartPie as falFileChartPie,
    faFileExport as falFileExport,
    faFileInvoice as falFileInvoice,
    faFileMedicalAlt as falFileMedicalAlt,
    faFileSpreadsheet as falFileSpreadsheet,
    faFilm as falFilm,
    faFilter as falFilter,
    faFolders as falFolders,
    faGavel as falGavel,
    faGlobeEurope as falGlobeEurope,
    faHistory as falHistory,
    faHorse as falHorse,
    faIdBadge as falIdBadge,
    faImage as falImage,
    faIndent as falIndent,
    faLevelDown as falLevelDown,
    faLineColumns as falLineColumns,
    faListAlt as falListAlt,
    faLocation as falLocation,
    faLongArrowLeft as falLongArrowLeft,
    faLongArrowRight as falLongArrowRight,
    faMapMarkerCheck as falMapMarkerCheck,
    faMailBulk as falMailBulk,
    faMarker as falMarker,
    faMobile as falMobile,
    faMoneyCheckEditAlt as falMoneyCheckEditAlt,
    faOutdent as falOutdent,
    faPassport as falPassport,
    faPause as falPause,
    faPencilAlt as falPencilAlt,
    faPhone as falPhone,
    faPhonePlus as falPhonePlus,
    faPhotoVideo as falPhotoVideo,
    faPlay as falPlay,
    faPlus as falPlus,
    faPlusCircle as falPlusCircle,
    faPrescriptionBottle as falPrescriptionBottle,
    faPrint as falPrint,
    faReceipt as falReceipt,
    faRepeatAlt as falRepeatAlt,
    faRoute as falRoute,
    faSave as falSave,
    faSearch as falSearch,
    faSignOutAlt as falSignOutAlt,
    faSortAlt as falSortAlt,
    faSpinner as falSpinner,
    faStethoscope as falStethoscope,
    faSync as falSync,
    faSyncAlt as falSyncAlt,
    faTable as falTable,
    faTags as falTags,
    faTasks as falTasks,
    faThLarge as falThLarge,
    faTimes as falTimes,
    faTimesCircle as falTimesCircle,
    faTimesSquare as falTimesSquare,
    faTrash as falTrash,
    faTrashAlt as falTrashAlt,
    faTools as falTools,
    faUser as falUser,
    faUserCog as falUserCog,
    faUserFriends as falUserFriends,
    faUserPlus as falUserPlus,
    faUsers as falUsers,
    faUsersCog as falUsersCog,
    faUsersCrown as falUsersCrown,
    faUserTie as falUserTie,
    faUserVisor as falUserVisor,
    faVenusMars as falVenusMars,
    faVials as falVials,
    faVolumeUp as falVolumeUp,
    faListAlt as faListAlt,
    faToolbox as faToolbox,
    faLink as falLink,
    faBold as falBold,
    faItalic as falItalic,
    faStrikethrough as falStrikethrough,
    faUnderline as falUnderline,
    faCode as falCode,
    faParagraph as falParagraph,
    faListUl as falListUl,
    faListOl as falListOl,
    faQuoteRight as falQuoteRight,
    faHorizontalRule as falHorizontalRule,
    faUndo as falUndo,
    faRedo as falRedo,
    faInfinity as falInfinity,
    faSignature as falSignature,
    faHorseSaddle as falHorseSaddle,
    faTachometerSlow as falTachometerSlow,
    faSyringe as falSyringe,
    faFileContract as falFileContract,
    faAlarmClock as falAlarmClock,
    faToolbox as falToolbox,
    faTh as falTh,
    faList as falList,
    faUpload as falUpload,
    faFileMedical as falFileMedical,
    faQuestion as falQuestion,
    faArrowsAltH as falArrowsAltH,
    faHome as falHome,
    faLanguage as falLanguage,
    faUserLock as falUserLock,
    faClock as falClock,
    faEye as falEye
} from '@fortawesome/pro-light-svg-icons'

// Solid
import {
	faAlignCenter as fasAlignCenter,
	faAlignJustify as fasAlignJustify,
	faAlignLeft as fasAlignLeft,
	faAlignRight as fasAlignRight,
	faAtomAlt as fasAtomAlt,
    faCircle as fasCircle,
    faCode as fasCode,
    faDownload as fasDownload,
    faExternalLink as fasExternalLink,
    faEye as fasEye,
    faHorseHead as fasHorseHead,
    faInboxOut as fasInboxOut,
    faInfoCircle as fasInfoCircle,
    faPencilAlt as fasPencilAlt,
    faSave as fasSave,
    faSpinner as fasSpinner,
	faSyncAlt as fasSyncAlt,
	faTextSize as fasTextSize,
    faUserTie as fasUserTie,
    faShippingFast as fasShippingFast,
    faTh as fasTh,
	faTint as fasTint,
	faList as fasList,
	faSquare as fasSquare,
	faWifiSlash as fasWifiSlash,
    faAngleDown as fasAngleDown,
    faHome as fasHome,
    faPlus as fasPlus,
    faExclamationTriangle as fasExclamationTriangle,
    faQuestion as fasQuestion,
    faCaretRight as fasCaretRight,
    faInfo as fasInfo,
    faTombstone as fasTombstone
} from '@fortawesome/pro-solid-svg-icons'


// Brands
import {
    faWhatsapp as fabWhatsapp
} from '@fortawesome/free-brands-svg-icons'

// DuoTone
import {
    faArrowCircleDown as fadArrowCircleDown,
    faCommentsAlt as fadCommentsAlt,
    faIdCard as fadIdCard,
    faSync as fadSync,
	faTruck as fadTruck,
	faTruckLoading as fadTruckLoading
} from '@fortawesome/pro-duotone-svg-icons'

// Custom
import { cfAddColAfter as cfAddColAfter } from 'GroomyRoot/assets/js/custom-fonts/cfAddColAfter.js'
import { cfAddColBefore as cfAddColBefore } from 'GroomyRoot/assets/js/custom-fonts/cfAddColBefore.js'
import { cfAddRowAfter as cfAddRowAfter } from 'GroomyRoot/assets/js/custom-fonts/cfAddRowAfter.js'
import { cfAddRowBefore as cfAddRowBefore } from 'GroomyRoot/assets/js/custom-fonts/cfAddRowBefore.js'
import { cfCombineCell as cfCombineCell } from 'GroomyRoot/assets/js/custom-fonts/cfCombineCell.js'
import { cfDeleteCol as cfDeleteCol } from 'GroomyRoot/assets/js/custom-fonts/cfDeleteCol.js'
import { cfDeleteRow as cfDeleteRow } from 'GroomyRoot/assets/js/custom-fonts/cfDeleteRow.js'
import { cfDeleteTable as cfDeleteTable } from 'GroomyRoot/assets/js/custom-fonts/cfDeleteTable.js'
import { cfAddTable as cfAddTable } from 'GroomyRoot/assets/js/custom-fonts/cfAddTable.js'
import { cfHubspot as cfHubspot } from 'GroomyRoot/assets/js/custom-fonts/cfHubspot.js'
import { cfPax as cfPax } from 'GroomyRoot/assets/js/custom-fonts/cfPax.js'
import { cfEchographie as cfEchographie } from 'GroomyRoot/assets/js/custom-fonts/cfEchographie.js'
import { cfDG as cfDG } from 'GroomyRoot/assets/js/custom-fonts/cfDG.js'
import { cfMare as cfMare } from 'GroomyRoot/assets/js/custom-fonts/cfMare.js'

// Regular
library.add(farAlignJustify)
library.add(farArrowRight)
library.add(farArrowDown)
library.add(farArrowUp)
library.add(farBell)
library.add(farCalculator)
library.add(farCalendar)
library.add(farCalendarAlt)
library.add(farCheck)
library.add(farCheckCircle)
library.add(farCheckDouble)
library.add(farCreditCard)
library.add(farFile)
library.add(farFileInvoice)
library.add(farFileMedicalAlt)
library.add(farFilePdf)
library.add(farGripLines)
library.add(farHandsUsd)
library.add(farHistory)
library.add(farLayerPlus)
library.add(farListUl)
library.add(farLocation)
library.add(farMapMarkerAlt)
library.add(farMapMarkerCheck)
library.add(farMoneyBillAlt)
library.add(farMoneyCheckEditAlt)
library.add(farPaperPlane)
library.add(farPen)
library.add(farPencilAlt)
library.add(farPlus)
library.add(farPrint)
library.add(farQuestionSquare)
library.add(farReceipt)
library.add(farRepeatAlt)
library.add(farRoute)
library.add(farSave)
library.add(farServer)
library.add(farSpinner)
library.add(farSquare)
library.add(farStickyNote)
library.add(farTable)
library.add(farTasks)
library.add(farTimes)
library.add(farTimesCircle)
library.add(farTrash)
library.add(farTrashAlt)
library.add(farUser)
library.add(farSignature)
library.add(farBold)
library.add(farItalic)
library.add(farUnderline)
library.add(farStrikethrough)
library.add(farGlobe)
library.add(farH1)
library.add(farH2)
library.add(farH3)
library.add(farH4)
library.add(farListOl)
library.add(farHorizontalRule)
library.add(farUndo)
library.add(farRedo)
library.add(farCamera)
library.add(farBracketsCurly)
library.add(farInfinity)
library.add(farHorse)
library.add(farClone)
library.add(farEraser)
library.add(farCogs)
library.add(farCalculatorAlt)
library.add(farCopy)
library.add(farAngleLeft)
library.add(farAngleRight)
library.add(farSort)
library.add(farRandom)
library.add(farWaveformPath)
library.add(farNotesMedical)
library.add(farArchive)
library.add(farExchangeAlt)
library.add(farSearch)
library.add(farStream)
library.add(farInfoCircle)
library.add(farInfo)
library.add(farAngleDown)
library.add(farAngleUp)
library.add(farUserLock)
library.add(farMoneyCheckEdit)
library.add(farChartArea)
library.add(farUndoAlt)
library.add(farLock)
library.add(farLockOpen)
library.add(farFileImport)
library.add(farUpload)
library.add(farPercent)
library.add(farDollyEmpty)


// Light
library.add(falAngleUp)
library.add(falAngleDown)
library.add(falAngleRight)
library.add(falArrowLeft)
library.add(falBallotCheck)
library.add(falBaby)
library.add(falBars)
library.add(falBell)
library.add(falBinoculars)
library.add(falBracketsCurly)
library.add(falCalculatorAlt)
library.add(falCalendar)
library.add(falCalendarAlt)
library.add(falCalendarWeek)
library.add(falCamera)
library.add(falCaretDown)
library.add(falChartPie)
library.add(falChartPieAlt)
library.add(falCheck)
library.add(falCheckCircle)
library.add(falChevronCircleRight)
library.add(falCube)
library.add(falCircleNotch)
library.add(falCrutch)
library.add(falCogs)
library.add(falDatabase)
library.add(falDesktop)
library.add(falDoorOpen)
library.add(falDownload)
library.add(falEdit)
library.add(falEnvelope)
library.add(falExclamationTriangle)
library.add(falExternalLinkSquareAlt)
library.add(falEyeSlash)
library.add(falFarm)
library.add(falFlagCheckered)
library.add(falFileAlt)
library.add(falFileChartPie)
library.add(falFileExport)
library.add(falFileInvoice)
library.add(falFileMedicalAlt)
library.add(falFileSpreadsheet)
library.add(falFilter)
library.add(falFilm)
library.add(falFolders)
library.add(falGavel)
library.add(falGlobeEurope)
library.add(falHistory)
library.add(falHorse)
library.add(falIdBadge)
library.add(falImage)
library.add(falIndent)
library.add(falLevelDown)
library.add(falLineColumns)
library.add(falListAlt)
library.add(falLocation)
library.add(falLongArrowLeft)
library.add(falLongArrowRight)
library.add(falMapMarkerCheck)
library.add(falMailBulk)
library.add(falMarker)
library.add(falMobile)
library.add(falMoneyCheckEditAlt)
library.add(falOutdent)
library.add(falPassport)
library.add(falPause)
library.add(falPencilAlt)
library.add(falPhone)
library.add(falPhonePlus)
library.add(falPhotoVideo)
library.add(falPlay)
library.add(falPlus)
library.add(falPlusCircle)
library.add(falPrescriptionBottle)
library.add(falPrint)
library.add(falReceipt)
library.add(falRepeatAlt)
library.add(falRoute)
library.add(falSave)
library.add(falSearch)
library.add(falSignOutAlt)
library.add(falSortAlt)
library.add(falSpinner)
library.add(falStethoscope)
library.add(falSync)
library.add(falSyncAlt)
library.add(falTable)
library.add(falTags)
library.add(falTasks)
library.add(falThLarge)
library.add(falTimes)
library.add(falTimesCircle)
library.add(falTimesSquare)
library.add(falTrash)
library.add(falTrashAlt)
library.add(falTools)
library.add(falUser)
library.add(falUserCog)
library.add(falUserFriends)
library.add(falUserPlus)
library.add(falUsers)
library.add(falUsersCog)
library.add(falUsersCrown)
library.add(falUserTie)
library.add(falUserVisor)
library.add(falVenusMars)
library.add(falVials)
library.add(falVolumeUp)
library.add(faListAlt)
library.add(faToolbox)
library.add(falBell)
library.add(falEyeSlash)
library.add(falBars)
library.add(falFileMedicalAlt)
library.add(falTimesSquare)
library.add(falFileSpreadsheet)
library.add(falLink)
library.add(falBold)
library.add(falItalic)
library.add(falStrikethrough)
library.add(falUnderline)
library.add(falCode)
library.add(falParagraph)
library.add(falListUl)
library.add(falListOl)
library.add(falQuoteRight)
library.add(falHorizontalRule)
library.add(falUndo)
library.add(falRedo)
library.add(falInfinity)
library.add(falSignature)
library.add(falHorseSaddle)
library.add(falTachometerSlow)
library.add(falSyringe)
library.add(falFileContract)
library.add(falAlarmClock)
library.add(falToolbox)
library.add(falTh)
library.add(falList)
library.add(falUpload)
library.add(falFileMedical)
library.add(falQuestion)
library.add(falArrowsAltH)
library.add(falHome)
library.add(falLanguage)
library.add(falUserLock)
library.add(falClock)
library.add(falEye)

// Solid
library.add(fasAlignCenter)
library.add(fasAlignJustify)
library.add(fasAlignLeft)
library.add(fasAlignRight)
library.add(fasAtomAlt)
library.add(fasCircle)
library.add(fasCode)
library.add(fasDownload)
library.add(fasExternalLink)
library.add(fasEye)
library.add(fasHorseHead)
library.add(fasInboxOut)
library.add(fasInfoCircle)
library.add(fasPencilAlt)
library.add(fasSave)
library.add(fasShippingFast)
library.add(fasSpinner)
library.add(fasSyncAlt)
library.add(fasTextSize)
library.add(fasTh)
library.add(fasTint)
library.add(fasList)
library.add(fasUserTie)
library.add(fasWifiSlash)
library.add(fasSquare)
library.add(fasAngleDown)
library.add(fasCaretRight)
library.add(fasHome)
library.add(fasPlus)
library.add(fasExclamationTriangle)
library.add(fasQuestion)
library.add(fasHome)
library.add(fasInfo)
library.add(fasTombstone)

// Brands
library.add(fabWhatsapp)

// DuoTone
library.add(fadArrowCircleDown)
library.add(fadCommentsAlt)
library.add(fadIdCard)
library.add(fadSync)
library.add(fadTruck)
library.add(fadTruckLoading)

// Custom
library.add(cfAddColAfter)
library.add(cfAddRowAfter)
library.add(cfAddColBefore)
library.add(cfAddRowBefore)
library.add(cfCombineCell)
library.add(cfDeleteCol)
library.add(cfDeleteRow)
library.add(cfDeleteTable)
library.add(cfAddTable)
library.add(cfHubspot)
library.add(cfPax)
library.add(cfEchographie)
library.add(cfDG)
library.add(cfMare)