const LOG_LEVEL = {
    DEBUG: 4,
    INFO: 3,
    WARN: 2,
    ERROR: 1
}

export default {
    data: () => ({
        logLevel: LOG_LEVEL.ERROR
    }),
    created() {
        this.logLevel = this.getConfig('debug_level')
    },
    methods: {
        onRollbarDebug(payload) {
            this.rollbarLog(LOG_LEVEL.DEBUG, 'debug', payload)
        },
        onRollbarInfo(payload) {
            this.rollbarLog(LOG_LEVEL.INFO, 'info', payload)
        },
        onRollbarWarn(payload) {
            this.rollbarLog(LOG_LEVEL.WARN, 'warn', payload)
        },
        onRollbarError(payload) {
            this.rollbarLog(LOG_LEVEL.ERROR, 'error', payload)
        },
        serializeRollbarPayload(payload) {
            return payload instanceof Error ? payload : JSON.stringify(payload)
        },
        rollbarLog(level, method, payload) {
            if (level <= this.logLevel) {
                this.$rollbar[method](this.serializeRollbarPayload(payload))
            }
        }
    }
}
