<template>
	<span>
		<span v-if="subDomainName">
			<custom-logo />
		</span>
		<span v-else>
    		<default-logo />
    	</span>   
	</span>

</template>

<script type="text/javascript">
	import { getSubDomainName } from "@/assets/js/common.js"

	export default {
        components: {
        	defaultLogo: () => import('GroomyRoot/components/Logos/LogoMenu_111'),
        	customLogo: () => {
        		const subDomain = getSubDomainName()
        		return import(`@/components/Logos/custom/${subDomain}/LogoMenu_111`)	
        	}
        }
    }
</script>

