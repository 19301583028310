import Vue from 'vue'
import Store from '@/store'
import Constants from 'Constants'
import RootConfigMixin from 'GroomyRoot/mixins/Config.js'

export default Vue.util.mergeOptions(RootConfigMixin, {
    methods: {
        loadConfig: async function() {
            try {
                // On continue d'envoyer la langue pour la rétrocompatibilité mais à enlever à la prochaine publi'
                const lang = Store.state.user.lang
                const url = Constants.GENERAL_CONFIG+"?lang=" + lang + "&licence_key="+Constants.USER_LICENCE_KEY
                const general_config = await this.$request.request_get_api("ConfigMixin::loadConfig", url)

                this.setConfig('user_access', this.$cryptaes.encrypt(general_config.retour.user_access))
                // this.setConfig('authorizedHorses', general_config.retour.authorizedHorses)
                // this.setConfig('authorizedHorsesLimit', general_config.retour.authorizedHorsesLimit)
                this.setConfig('acte_groupes', general_config.retour.acte_groupes)
                this.setConfig('actes_types', general_config.retour.actes_types)
				// this.setConfig('question_input_types', general_config.retour.question_input_types)
                this.setConfig('horse_race', general_config.retour.horse_race)
                this.setConfig('horse_robe', general_config.retour.horse_robe)
                this.setConfig('horse_sexe', general_config.retour.horse_sexe)
                this.setConfig('table_config', general_config.retour.table_config)
                this.setConfig('action_button', general_config.retour.action_button)
                this.setConfig('action_filter', general_config.retour.action_filter)
                this.setConfig('lieu_types',    general_config.retour.lieu_types)
                // this.setConfig('payment_method', general_config.retour.payment_method)
                // this.setConfig('payment_bank', general_config.retour.payment_bank)
                // this.setConfig('check_deposit_status', general_config.retour.check_deposit_status)
                // this.setConfig('vat', general_config.retour.vat)
                // this.setConfig('export_config', general_config.retour.export_config)
                this.setConfig('debug_level', general_config.retour.debug_level)
                this.setConfig('need_reset', general_config.retour.need_reset)
                this.setConfig('need_export', general_config.retour.need_export)
                this.setConfig('lang', general_config.retour.lang || 'fr')
                this.setConfig('translations', general_config.retour.translations)

                if(general_config.retour.pax_countries) {
                    this.setConfig('pax_countries', general_config.retour.pax_countries)
                }
            }
            catch(error) {
                console.error("ConfigMixin::loadConfig => An error occured:", error)
                return false
            }
            return true
        },

        loadTranslation: async function() {
            const lang = Store.state.user.lang
            const url = Constants.GET_TRANSLATION
            const general_config = await this.$request.request_get_api("ConfigMixin::loadTranslation", url)

            this.setConfig('translations', general_config.retour)
        }
    }
})
