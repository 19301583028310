
const state = {
	licence		: 0,
	licence_key	: '',
	permissions	: '',
	horse_whitelist:  [],
	lang		: 'fr'
}

const mutations = {
	setPermissions(state, permissions) {
		state.permissions = permissions
	},

	setHorseWhitelist(state, horse_whitelist) {
		state.horse_whitelist = horse_whitelist
	},

    setLang(state, lang) {
        state.lang = lang
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
