<template>
	<div class="container-fluid">
		<div v-if="needButtons">
			<b-button
				v-for="shutter in orderedShutters"
				:key="`head-${shutter.name}`"
				:variant="activeShutter === shutter.name ? 'primary shutter-btn' : 'secondary shutter-btn'"
				@click="goTo(shutter.name)"
			>
				{{ shutter.title }}
			</b-button>
		</div>

		<swiper
			ref="swiper"
			class="shutter_panel"
			style="overflow:visible"
			:options="swiperOptions"
		>
			<swiper-slide
				v-for="shutter in orderedShutters"
				:key="shutter.name"
				:class="(activeShutter === shutter.name ? 'swiper-slide-active-custom' : '') + ' ' + shutter.name"
			>
				<div :class="{ box: !shutter.noClass }" @mousedown="goTo(shutter.name)">
					<component
						:ref="shutter.name"
						:is="shutter.cmp"
						v-bind="shutter.props"
						@ok="ok(shutter.name, $event)"
					/>
				</div>
			</swiper-slide>
		</swiper>
	</div>
</template>

<script>
/**
 * DOCUMENTATION DISPONIBLE SUR LE WIKI GROOMY-WEB
 * @see https://gitlab.com/groomy/groomy-web/-/wikis/ShutterPanel
 */
 
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import _cloneDeep from 'lodash/cloneDeep'
import _last from 'lodash/last'

export default {
	name: 'ShutterPanel',
	components: {
		'swiper': swiper,
		'swiper-slide': swiperSlide
	},
	data() {
		return {
			/**
			 * Objet contenant en clé le nom unique du volet et en valeur ses infos
			 */
			shutters: {},
			/**
			 * Nom du composant avec le focus
			 */
			activeShutter: null
		}
	},
	computed: {
		/**
		 * Instance du swiper de SwiperJs pour intéragir avec lui
		 * @see https://swiperjs.com/api/#methods
		 */
		swiper() {
			return this.$refs.swiper.swiper
		},
		/**
		 * Options du swiper de SwiperJs
		 * @see https://swiperjs.com/api/#parameters
		 */
		swiperOptions() {
			return {
				slidesPerView: 'auto',
				spaceBetween: 0,
				cssMode: true,
				simulateTouch: false,
			}
		},
		/**
		 * Détermine si on affiche ou non les boutons de navigation au dessus des Shutters
		 */
		needButtons() {
			return this.orderedShutters.length > 1
		},
		/**
		 * Shutters triés pour conserver leur ordre d'ajout et pas l'ordre
		 * alphabétique des clés de this.shutters
		 */
		orderedShutters() {
			return Object.values(this.shutters).sortBy('order')
		}
	},
	methods: {
		/**
		 * Ajoute ou remplace puis slide vers un shutter
		 * @param {Object} shutter Shutter à ouvrir
		 * @param {String} shutter.name Identifiant unique du shutter
		 * @param {String} shutter.title Titre affiché dans les boutons de navigation
		 * @param {String} shutter.cmpPath Chemin du composant à partir du dossier 'src' Ex: "components/WriteActe"
		 * @param {Object} shutter.props Objet contenant les props à mettre sur le nouveau composant instancié
		 * @param {Function} shutter.onOk Méthode appelée lorsqu'un volet a terminé son travail: lorsqu'il
		 * appelle la méthode this.ok() de la mixin Shutter
		 * @param {Function} shutter.onCancel Méthode appelée lorsqu'un volet est annulé: lorsqu'il
		 * appelle la méthode this.cancel() de la mixin Shutter
		 */

		open(shutter) {
			return new Promise(resolve => {
				shutter.props = shutter.props || {}
				// On donne les shutterData car ils sont utilisés par la mixin Shutter,
				// notamment lors de l'appel aux méthodes this.ok() et this.cancel()
				shutter.props.shutterData = {
					name: shutter.name
				}

				// Utilisation du this.$set pour mettre à jour l'interface meme pour
				// un nouveau shutter (nouvelle clé sur l'objet this.shutters)
				this.$set(
					this.shutters,
					shutter.name,
					{
						name: shutter.name,
						title: shutter.title,
						order: this.orderedShutters.length,
						cmp: () => {
							// Pour les composants GroomyRoot, on est obligé de construire le chemin avec l'alias GroomyRoot directement
							// dans l'import JS, pour qu'il soit remplacé à la compilation, par le vrai chemin complet
							if(shutter.cmpPath.indexOf('ShutterGroomyRoot') > -1) {
								const tmp = shutter.cmpPath.replace('ShutterGroomyRoot/', '')
								return import(`ShutterGroomyRoot/${tmp}.vue`)
							}
							return import(`ShutterRoot/${shutter.cmpPath}.vue`)
						},
						props: shutter.props,
						onOk: shutter.onOk,
						onCancel: shutter.onCancel,
						noClass: shutter.noClass || false
					}
				)

				// On attend que l'interface soit rendue puis on slide sur ce shutter
				this.$nextTick(() => {
					this.goTo(shutter.name)
					window.scrollTo(0,0) // Puis on remonte en haut de la page
					resolve()
				})
			})
			
		},

		/**
		 * Changer la valeur du prop d'un volet
		 * @param {String} name Identifiant unique du volet
		 * @param {String} propName Nom du prop
		 * @param {Any} value Valeur à assigner au prop <propName>
		 */
		update(name, propName, value) {
			if(this.shutters[name]) {
				this.$set(
					this.shutters[name].props,
					propName,
					value
				)
			}
		},

        /**
         * Reload les données du shutter éventuellement présent (pour permettre de reload toutes les données pas ex)
         * @param {String} name Identifiant unique du volet
         */
        reload(name) {
			const shutter = this.get(name)
            if(!shutter) return

			const reloadMethod = shutter.reloadMethod

            if(shutter[reloadMethod] && typeof(shutter[reloadMethod]) == 'function') {
                shutter[reloadMethod].apply(shutter)
            }
        },

		/**
		 * Fermer complètement un volet
		 * @param {String} name Identifiant unique du volet à fermer
		 */
		close(name) {
			this.$delete(this.shutters, name)
		},

		/**
		 * Slide vers un volet ouvert
		 * @param {String} name Identifiant unique du volet
		 */
		goTo(name) {
			const slideIdx = this.orderedShutters.findIndex(s => s.name === name)
			if (slideIdx !== -1) {
				this.swiper.slideTo(slideIdx)
			}

			this.activeShutter = name

		},

        get(name) {
            return this.$refs[name] ? this.$refs[name][0] : null
        },

		/**
		 * Ferme tous les volets sauf le premier ouvert
		 */
		keepFirst() {
			this.orderedShutters.slice(1).forEach((shutter) => {
				this.close(shutter.name)
			})
		},

		/**
		 * Ferme le dernier volet, et swiper à l'avant dernier
		 */
		keepLast() {
			const last = _last(this.orderedShutters)
			this.close(last.name)

			const newLast = _last(this.orderedShutters)
			this.goTo(newLast.name)
		},

		/**
		 * Ferme tous les volets placés après celui donné
		 */
		keepGiven(name) {
			const targetIndex = this.orderedShutters.findIndex(sht => sht.name == name)
			const length = this.orderedShutters.length
			let names = []

			for(let i=targetIndex+1; i<length; i++) {
				names.push(this.orderedShutters[i].name)
			}

			names.forEach(name => {
				this.close(name)
			})

			const newLast = _last(this.orderedShutters)
			this.goTo(newLast.name)
		},

		focusPrevious() {
			let orderedShutters = _cloneDeep(this.orderedShutters)
			orderedShutters.pop()
			const last = _last(orderedShutters)
			this.goTo(last.name)
		},

		/**
		 * Indique si un volet est déjà ouvert ou non
		 * @param {String} name Identifiant unique du volet
		 */
		isOpen(name) {
			return !!this.shutters[name]
		},

		/**
		 * Retourne la position d'un shutter donné, parmis les orderedShutters
		 */
		getShutterIndex(name) {
			return this.orderedShutters.findIndex(sh => sh.name == name)
		},

		/**
		 * Méthode appelée lorsqu'un volet a terminé son travail
		 * /!\ Cette méthode ne doit pas etre directement appelée mais la mixin Shutter
		 * doit impérativement etre utilisée
		 */
		ok(name, payload) {
			if (this.shutters[name] && this.shutters[name].onOk) {
				this.shutters[name].onOk(payload)
			}
		},

		/**
		 * Méthode appelée lorsqu'on annule un volet
		 */
		cancel(name, reason) {
			if (this.shutters[name] && this.shutters[name].onCancel) {
				this.shutters[name].onCancel(reason)
			}
			this.close(name)
		}
	},
	watch:{
		'orderedShutters.length'(val){
			this.$nextTick(() => {
				this.swiper.update()
			})
		}
	}
}
</script>
