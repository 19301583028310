import Constants from 'Constants'

var CacheMixin = {
    methods: {
        getCache: function(type, id) {
            if(this.$cache[type]) {
                return this.$cache[type][id]
            }
            return false
        },

        setCache: function(type, id, value) {
            if(!this.$cache[type]) {
                this.$cache[type] = {}
            }
            if(type) {
                this.$cache[type][id] = value
            }
        },

        rmCache: async function(type, id) {
            if(Array.isArray(type)) {
                for (let i=0; i<type.length; i++) {
                    this.$cache[type[i]][id] = null
                }
            }
            else {
                this.$cache[type][id] = null
            }
        },

        rmConfigCache: function() {
            Constants.USER_LICENCE_KEY = ''
            Constants.USER_ALREADY_LOGGED = false
        },

        updateCache: function(type, id, value) {
            this.rmCache(type, id)
            this.setCache(type, id, value)
        }
	}
}

export default CacheMixin
