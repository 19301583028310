/* eslint-disable class-methods-use-this */
import { Mark } from 'tiptap'
import { toggleMark } from 'tiptap-commands'

export default class TextColor extends Mark {

	get name () {
		return 'textcolor'
	}

	get defaultOptions () {
		return {
			color: ['red'],
		}
	}

	get schema () {
		return {
			attrs: {
				color: {
					default: 'rgba(0,0,0,1)',
				},
			},
            parseDOM: [{
				style: 'color',
				getAttrs: value => value.indexOf('rgba') !== -1 ? { color: value } : ''
			}],
			toDOM: node => {
				return ['span', {
					style: `color:${node.attrs.color}`
				}, 
				0
			]}
		}
	}

	commands ({ type }) {
		return (attrs) => toggleMark(type, attrs)
	}
}