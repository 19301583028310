import Vue from 'vue'
import RootNotesMixin from 'GroomyRoot/mixins/Notes.js'
import Constants from 'Constants'

export default Vue.util.mergeOptions(RootNotesMixin, {
    methods: {
        getNotes: async function() {
            const url = Constants.NOTES_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            
            return this.$request.request_get_api("NotesMixin::getNotes", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("NotesMixin::getNotes => ", e)
                    return null
                })
        },

        getNotesFeed: async function(page) {
            const url = Constants.NOTES_FEED_URL+`?licence_key=${Constants.USER_LICENCE_KEY}&page=${page}`
            
            return this.$request.request_get_api("NotesMixin::getNotesFeed", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("NotesMixin::getNotesFeed => ", e)
                    return null
                })
        },

        getHorseNotesFeed: async function(horse_id, page) {
            const url = this.constructRoute(Constants.NOTES_HORSE_FEED_URL, { horse_id })+`?licence_key=${Constants.USER_LICENCE_KEY}&page=${page}`
            
            return this.$request.request_get_api("NotesMixin::getHorseNotesFeed", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("NotesMixin::getHorseNotesFeed => ", e)
                    return null
                })
        },

        getNote: async function(note_id) {
            const url = this.constructRoute(Constants.NOTES_DETAILS_URL, { note_id })+"?licence_key="+Constants.USER_LICENCE_KEY
            
            return this.$request.request_get_api("NotesMixin::getNote", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("NotesMixin::getNote => ", e)
                    throw e
                })
        },

        getNoteFiles: async function(note_id) {
            const url = this.constructRoute(Constants.NOTES_FILES_URL, { note_id })+"?licence_key="+Constants.USER_LICENCE_KEY
            
            return this.$request.request_get_api("NotesMixin::getNoteFiles", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("NotesMixin::getNoteFiles => ", e)
                    throw e
                })
        },

        getNoteFileContent: async function(note_id) {
            const url = this.constructRoute(Constants.NOTES_FILE_CONTENT_URL, { note_id })+"?licence_key="+Constants.USER_LICENCE_KEY
            
            return this.$request.request_get_api("NotesMixin::getNote", url)
                .catch(e => {
                    console.error("NotesMixin::getNote => ", e)
                    throw e
                })
        },

        getHorseNotes: async function(horse_id) {
            const url = this.constructRoute(Constants.NOTES_HORSE_GET_URL, { horse_id })+"?licence_key="+Constants.USER_LICENCE_KEY

            return this.$request.request_get_api("NotesMixin::getNote", url)
                .then(res => {
                    return res.retour
                })
                .catch(e => {
                    console.error("NotesMixin::getNote => ", e)
                    throw e
                })
        }
    }
})