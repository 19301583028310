<template>
	<div id="app" class='w-100'>
		<Header :client-area="true" />
		<router-view></router-view>
	</div>
</template>

<script>
import { EventBus } from 'EventBus';
import _debounce from 'lodash/debounce'
import Header from "@/components/Header";
import User from  "@/mixins/User.js";
import Rollbar from  "@/mixins/Rollbar.js";
import Window from  "@/mixins/Window.js";

export default {
	name: "App",
	mixins: [User, Rollbar, Window],
	components: {
		Header
	},
	data: () => ({
		debouncedForceDelogUser: null
	}),
	computed: {
		disableScroll() {
			return (
				this.isBelowMd
				&& (
					this.$store.state.nav.isOpen
					|| this.$store.state.nav.isFooterOpen
					|| this.$store.state.nav.isNotificationsOpen
				)
			)
		}
	},
	created() {
		this.debouncedForceDelogUser = _debounce(
			this.forceDelogUser,
			3000,
			{ 'leading': true, 'trailing': false }
		)
		EventBus.$on('App::forceDelogUser', this.debouncedForceDelogUser)
		EventBus.$on('App::failureToast', this.failureToast)
		EventBus.$on('Rollbar::debug', this.onRollbarDebug)
		EventBus.$on('Rollbar::info', this.onRollbarInfo)
		EventBus.$on('Rollbar::warn', this.onRollbarWarn)
		EventBus.$on('Rollbar::error', this.onRollbarError)
	},
	mounted() {
		this.init_component()
	},
	destroyed() {
		EventBus.$off('App::forceDelogUser', this.debouncedForceDelogUser)
		EventBus.$off('App::failureToast', this.failureToast)
		EventBus.$off('Rollbar::debug', this.onRollbarDebug)
		EventBus.$off('Rollbar::info', this.onRollbarInfo)
		EventBus.$off('Rollbar::warn', this.onRollbarWarn)
		EventBus.$off('Rollbar::error', this.onRollbarError)
	},
	watch: {
		disableScroll(val) {
			this.updateScrollState()
		}
	},
	methods: {
		async init_component() {
			let valid_user = this.getConfig("valid_user")
			if(valid_user == 0) {
				EventBus.$emit("verified_user", false)
			}
			else {
				EventBus.$emit("verified_user", true)
			}
		},
		updateScrollState() {
			if (this.disableScroll) {
				document.querySelector('html').classList.add('no_scroll')
			}
			else {
				document.querySelector('html').classList.remove('no_scroll')
			}
		}
	}
};
</script>
