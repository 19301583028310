export default {
    methods: {
        /* Files identification */
        sourceFile(file) {
            if(this.isEmbeddRendable(file)) return file.files_url
            return file.files_s3_url
        },
        isIframeRendable(file) {
            const compatible_types = ['application']
            return compatible_types.indexOf(file.files_mimetype) > -1
        },
        isImgRendable(file) {
            return file.files_mimetype == 'image'
        },
        isAudioRendable(file) {
            return file.files_mimetype == 'audio'
        },
        isVideoRendable(file) {
            return file.files_mimetype == 'video'
        },
        isEmbeddRendable(file) {
            return file.files_mimetype == 'embedded'
        }
    }
}