import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import root from 'GroomyRoot/store/root'
import nav from 'GroomyRoot/store/modules/nav'
import user from 'GroomyRoot/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        common,
		nav,
		user
    },
    ...root
})
