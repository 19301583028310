import Vue from 'vue'
import RootPermissionMixin from 'GroomyRoot/mixins/Permissions.js'
import Cryptaes from 'GroomyRoot/assets/js/cryptaes.js'

export default Vue.util.mergeOptions(RootPermissionMixin, {
    methods: {
        fetchAuthorizedHorses() {
            return this.getConfig('authorizedHorses')
		},

        getUserAccessRightsThroughLocalstorage() {
			const data = this.getConfig('user_access')
            const crypt_obj = new Cryptaes()
			return crypt_obj.decrypt(data)
        },

		initMenuRights() {
            this.initTabsRights()
			let access = this.getUserAccessRights()

			if(!access) return
			access = access.split('-')

			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasMvpAccess', val: access.indexOf("MVP") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasEmailVerified', val: access.indexOf("VRFD") > -1 })

			// Menus "Factures fournisseurs" et "Paiements fournisseurs", passant à true si un des clients groomy, ayant l'option facture fournisseur, possède un tiers à la même adresse mail
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasInvoiceSupplier', val: access.indexOf("IFOURNC") > -1 }) 

			// Permissions générales
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasFacturationAccess', val: access.indexOf("CAFACT") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasReglementsAccess', val: access.indexOf("CAREGL") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasSaillieAccess', val: access.indexOf("CASAILL") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasActesAccess', val: access.indexOf("CAACTE") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasSocialFeedAccess', val: access.indexOf("CAFLA") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasMonteInfosAccess', val: access.indexOf("CAMI") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasSuiviCroissanceAccess', val: access.indexOf("SVCROI") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasPaymentMangopayAccess', val: access.indexOf("MANG") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasPaymentMoneticoAccess', val: access.indexOf("MONET") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasPaymentAtosAccess', val: access.indexOf("ATOS") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasPaymentPayboxAccess', val: access.indexOf("PAYBOX") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasPaymentGocardlessAccess', val: access.indexOf("GOCAR") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasPaymentSogecommerceAccess', val: access.indexOf("SOGE") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasPaxAccess', val: access.indexOf("CAPAX") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasFLCAccess', val: access.indexOf("CAFLC") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasResaAccess', val: access.indexOf("CARESA") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasHorseAccess', val: access.indexOf("CAHRS") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasMareAccess', val: access.indexOf("CAMARE") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasEffectifAccess', val: access.indexOf("CALE") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasSignatureAccess', val: access.indexOf("SIGNELEC") > -1 })

			// Permissions héritées de la licence
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasInheritedlicenceFacturationAccess', val: access.indexOf("ALHFACT") > -1 })
        },

        initTabsRights() {
            const data = this.getConfig('authorized_tabs')
            const crypt_obj = new Cryptaes()
			
            let tabs = crypt_obj.decrypt(data)
            if(!tabs) {
                return 
            }

            tabs = JSON.parse(tabs)
			this.$store.commit('common/setAuthorizedTabs', tabs)
        }
    }
})
