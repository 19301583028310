import Vue from 'vue'
import Request from '@/assets/js/requests.js'
import { loadLocale } from '../assets/js/i18n.js'

import CacheMixin from '@/mixins/Cache.js'
import ConfigMixin from '@/mixins/Config.js'
import HorseMixin from '@/mixins/Horse.js'
import Tools from '@/mixins/Tools.js'

import Constants from 'Constants'

var UserMixin = {
    mixins: [ConfigMixin, HorseMixin, Tools, CacheMixin],
    data() {
        return {
            request: new Request(null),
            has_multi_licences: false,
        }
	},
	computed: {
		userLang: {
			get() {
				return this.$store.state.user.lang
			},
			set(val) {
				this.$store.commit('user/setLang', val)
			}
		}
	},
	methods: {
        checkLocal: function() {
            let saved_day = ConfigMixin.methods.getConfig('today')
            if(saved_day) {
                if(new Date(saved_day).getDate() !== new Date().getDate()) {
                    let tables_with_dates = [
                        'horse_planning',
                        'horse_actes',
                        'mouvements',
                        'mouvements_horse',
                        'planning',
                        'liste_actes',
                        'ListInvoice',
                        'AValider'
                    ]

                    tables_with_dates.forEach(table => {
                        localStorage.removeItem('startDate' + table)
                        localStorage.removeItem('endDate' + table)
                    });

                    ConfigMixin.methods.setConfig('today', new Date())
                }
            }
            else {
                ConfigMixin.methods.setConfig('today', new Date())
            }

            const licence = ConfigMixin.methods.getConfig('licence_key')
            const bearer = ConfigMixin.methods.getConfig('token')
            const user_id = ConfigMixin.methods.getConfig('user_id')
            const valid_user = ConfigMixin.methods.getConfig('valid_user')

            return bearer && licence && user_id && valid_user != undefined
        },

        checkLogin: async function(email, password) {
            let response = null
            this.has_multi_licences = false
            
            try {
                response = await this.$request.request_post_api("UserMixin::checkLogin", Constants.USER_LOGIN, {
                    email: email,
                    password: password
                }, false, { sync:false })

                if(response.code_retour === 0) {
                    const data = response.retour

					this.persistUserSession(data.token, data.licence_key, data.licence_username, data.user_id, data.valid_user, data.valid_mail, data.lang)
					this.persistUserLicence(data)

                    if(data.all_licences.length > 1) {
                        this.persistUserLicences(data.all_licences)
                        this.has_multi_licences = true
                    }
                }
            }
            catch(error) {
                console.error("UserMixin::checkLogin => An error occured:", error)
                return false
            }
            return true
        },

        async loginUser(email, password, redirect=null) {
            try {
                const res = await this.checkLogin(email, password)

                if(!res) {
                    throw new Error(this.getTrad('compte.bad_identifiants'))
                }
                else {
                    // On regarde si le user a plusieurs licences
                    if(this.has_multi_licences) {
						const query = redirect ? { redirect: redirect } : null
                        this.$router.push({ name: 'switchLicence', query: query })
                        return
                    }

                    // On charge la config en cache (localstorge), et on fait la redirection
                    this.redirectPostLogin(redirect)
                }
            } catch (err) {
                this.$request.parseError(err)
            }
		},
		
		setUserLoggedIn() {
            this.$router.user_logged = true
		},

        async initSession() {
            const licence = this.getConfig('licence_key')
            const licence_name = this.getConfig('licence_name')
            const bearer = this.getConfig('token')
			
			Constants.USER_LICENCE_KEY = licence
			Constants.USER_NAME = licence_name

            this.request.set_headers(bearer)
			this.setUserLoggedIn()
			this.setLanguage(this.getConfig('lang', 'fr'))
        },

        redirectPostLogin(redirect='/horse') {
			this.$router.push({
				name: 'Loading',
				query: {
					redirect
				}
			})
        },

        autologUser: async function(email) {
            const url = Constants.USER_LOGIN_ADMIN + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("UserMixin::autologUser", url, {
                email: email
            }, false)
        },

        persistUserSession: function(bearer, licence, licence_name, user_id, valid_user, valid_mail, lang) {
            this.setConfig("licence_key", licence)
            this.setConfig("licence_name", licence_name)
            this.setConfig("token", bearer)
            this.setConfig("user_id", user_id)
			this.setConfig("valid_user", valid_user)
            this.setConfig("lang", lang || 'fr')
			
			this.persistUserMailValid(valid_mail)
            this.request.set_headers(bearer)
		},

		persistUserMailValid: function(mail_valid=false) {
			this.setConfig("valid_mail", mail_valid)
		},
		
		persistUserLicence: function(data) {
			const to_store = {	
				licence_key: data.licence_key,
				licence_username: data.licence_username,
				licence_date_of_validity: data.licence_date_of_validity
			}

			this.setConfig("main_licence", to_store)
		},

		// Les licences mises en local storage ne seront utilisées que lors du login
        persistUserLicences: function(licences) {
            this.setConfig("licences", licences)
        },

        persistUserLicenceKey: function(licence_key) {
            Constants.USER_LICENCE_KEY = licence_key
            this.setConfig("licence_key", licence_key)
		},
		
		persistUserLicenceName: function(licence_name) {
			Constants.USER_NAME = licence_name
            this.setConfig("licence_name", licence_name)
		},

        persistUserConfig: async function() {
            const config_loaded = await this.loadConfig()

            if(config_loaded) {
                this.initUserConfig()
                return true
            }
		},

        createUser: async function(user, from = '', salon = false) {
            let res = { code_retour: false, retour: '' }

            await this.axios.post(Constants.USER_CREATE, {
                name: user.name,
                firstname: user.firstname,
                email: user.mail.trim(),
                password: user.password,
                password_confirmation: user.password_confirmation,
                code_parrain: user.code_parrain,
                indicatif: user.indicatif,
                phone: user.phone,
                country: user.country,
                from: from,
                salon: salon
            }).then(async response => {
                if(response.data.code_retour === 0) {
                    res.code_retour = true
                    res.retour = response.data.retour
                }
            })
            .catch(error => {
                if(error.response && error.response.data) {
					if(error.response.data.code_retour == "NOR") {
						res.retour = "compte.erreur_parrain"
					}
					else if(error.response.data.code_retour == "PHUSE") {
						res.retour = "compte.erreur_phone_already_use"
					}
					else if(error.response.data.code_retour == "IF") {
						res.retour = "compte.erreur_phone"
					}
					else {
						res.retour = error.response.data.retour
					}
                }
            })

            return res
        },

        updatePassword(email, token, password) {
            const url = Constants.UPDATE_PWD_URL
            const params = {
                email,
                token,
                password
            }

            return this.$request.request_post_api("UserMixin::updatePassword", url, params, false)
            .catch(this.$request.parseError)
        },

        validateCode: async function(phone, indicatif, code) {
            let params = {
                phone: phone,
                indicatif: indicatif,
                code: code,
            }

            const url = Constants.USER_VALIDATION_CODE
            const result = await this.$request.request_post_api("UserMixin::validateCode", url, params, false)
            .catch(error => {
                console.error("UserMixin::validateCode => ERROR", error)
                this.failureToast("toast." + error.response.data.message)
                return null
            })

            return result
        },

        validatePhoneNumber: async function(phone, country, user_id) {
            let params = {
                phone: phone,
                country: country,
                user_id: user_id,
            }

            const url = Constants.USER_VALIDATION_PHONE
            const result = await this.$request.request_post_api("UserMixin::validatePhoneNumber", url, params, false)
            .catch(error => {
                console.error("UserMixin::validatePhoneNumber => ERROR", error)
                this.failureToast("toast." + error.response.data.message)
                return null
            })

            return result
        },

        askValidationEmail: async function(temp_licence='') {
            const url = Constants.USER_VALIDATION_ASK + "?licence_key=" + temp_licence || Constants.USER_LICENCE_KEY
            const result = this.$request.request_post_api("UserMixin::askValidationEmail", url, [], false).catch(e => {
                console.error("UserMixin::getUserById => ERROR", e)
                this.failureToast()
                return null
            })

            return result
        },

        validateEmail: async function(user_id, token, signature) {
            // On reconstruit l'url de validation avec sa signature, à partir des params
            const url = this.constructRoute(Constants.USER_VALIDATION_EMAIL, {
                user_id: user_id,
                token: token,
                signature: signature
			})

            const result = await this.$request.request_get_api("UserMixin::validateEmail", url, false)
            .catch(e => {
                console.error("UserMixin::validateEmail => Error:", e)
                return {
                    type: 'failure',
                    result: e.request.response,
                    status: e.request.status
                }
            })

            if(!result.retour) return result

            return {
                type: 'success',
                result: result.retour
            }
        },


        getUserById: async function(user_id) {
            const params = { 'id': user_id }
            const url = this.constructRoute(Constants.USER_GET_ID, params)
            const result = await this.$request.request_get_api("UserMixin::getUserById", url, false)
            .catch(error => {
                console.error("UserMixin::getUserById => ERROR", error)
                this.failureToast()
                return null
            })

            return result.retour
        },

        // Permet de delog le user, en supprimant la section "configuration.actual_user" de la conf de l'app
        delogUser: async function(keep_config=false) {
			try {
				const table = await this.$storage.db.t('_sync_temp')
				const hasData = await table.count()
				const isOnline = window.navigator.onLine
				let message

				// Si on est hors ligne on prévient qu'il ne pourra pas se reco
				if(!isOnline && hasData <= 0) {
					message = this.getTrad('toast.logout_message_offline')
				}
				// Si il y a des data en attente de synchro (_sync_temp)
				else if(hasData > 0) {
					// Si on peut pas sync parce qu'il est hors ligne
					if (!isOnline) {
						message = this.getTrad('toast.logout_message_sync_offline')
					}
					else {
						try {
							await this.$sync.force(false)
						}
						// Si il y a eu une erreur de synchro on prévient
						catch (err) {
							console.error(err)
							message = this.getTrad('toast.logout_message_sync_error')
						}
					}
				}

				if (message) {
					const modalAccepted = await this.$bvModal.msgBoxConfirm(message,{
						noCloseOnBackdrop: true,
						noCloseOnEsc: true,
						centered: true,
						okTitle: this.getTrad('global.oui'),
						cancelTitle: this.getTrad('global.non'),
					})
	
					if(!modalAccepted) {
						return
					}
				}

				await this.$storage.removeDatabase()
			}
			catch(err) {
				console.error(err)
			}

            try {
                await this.$firebase.messaging.reset()
            }
            finally {
				if(!keep_config) {
					this.rmConfig()
					this.rmConfigCache()
					this.request.reset_header()
		
					this.$router.user_logged = false

					// On ne redirige que si le delog ne vient pas du switch licence
					if(this.$route.name != "Home") {
						this.$router.push("/")
					}
				}
            }
        },

        forceDelogUser: async function() {
            await this.delogUser()
            this.failureToast('toast.session_expired')
        },

        // Récupération des paramètres user dans local storage
        initUserConfig: function() {
            // On récupère le paramètre de resize du menu header
			let user_params = this.getConfig('user')

            if(!user_params) {
                user_params = {}
                user_params.menu_open = true

                this.setConfig('user', user_params)
            }
			const menu_open = user_params.menu_open
			if (menu_open) {
				this.$store.commit('nav/open')
			}
			else {
				this.$store.commit('nav/close')
			}
        },

        // Pour le moment, ne retourne que la config du menu (ouvert ou fermé par défaut)
        getUserMenuConfig: function() {
            const user_config = this.getConfig('user')

            return {
                'menu_open': user_config.menu_open
            }
        },

        //
        updateUserMenuConfig: function(type, value) {
            if(type == 'menu_open') {
				this.setConfig('user', { menu_open: value } )
				if (value) {
					this.$store.commit('nav/open')
				}
				else {
					this.$store.commit('nav/close')
				}
            }
        },

        // Retourne la liste des gammes de licences
        getGammes: async function() {
            const url = Constants.LICENCES_GAMMES_URL+"?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("UserMixin::getGammes", url)
            .catch(error => {
                console.log("UserMixin::getGammes => ERROR", error)
                return null
            })
            if(result.code_retour == 0) return result.retour
            return
        },

        // Retourne la liste des options existantes
        getOptions: async function() {
            const url = Constants.LICENCES_OPTIONS_URL+"?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("UserMixin::getGammes", url)
            .catch(error => {
                console.log("UserMixin::getGammes => ERROR", error)
                return null
            })
            if(result.code_retour == 0) return result.retour
            return
		},
		
		// Retourne la liste des options existantes
		getSupplements: async function() {
			const url = Constants.LICENCES_SUPPLEMENTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("UserMixin::getSupplements", url)
			.catch(error => {
				console.log("UserMixin::getSupplements => ERROR", error)
				return null
			})
			if(result.code_retour == 0) return result.retour
			return
		},

        // Affecte une gamme à une licence pour un utilisateur
        setLicenceGamme: async function(email, userLicenceKey, gammeId) {
            const url = Constants.LICENCES_SET_GAMME_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const params = {"user_email":email, "user_licence_key":userLicenceKey, "gamme_id":gammeId}
            return await this.$request.request_post_api("UserMixin::setLicenceGamme", url, params, false)
        },

        setLicenceOptions: async function(email, userLicenceKey, optionsIds) {
            const url = Constants.LICENCES_SET_OPTIONS_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const params = {"user_email":email, "user_licence_key":userLicenceKey, "options_ids":optionsIds}
            return await this.$request.request_post_api("UserMixin::setLicenceGamme", url, params, false)
		},
		
		setLicenceSupplements: async function(email, userLicenceKey, supplements_ids) {
			const url = Constants.LICENCES_SET_SUPPLEMENTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const params = {"user_email":email, "user_licence_key":userLicenceKey, "supplements_ids":supplements_ids}
			return await this.$request.request_post_api("UserMixin::setLicenceSupplements", url, params, false)
		},

        // Récupérer l'id de la gamme associée à un utilisateur
        getUserGamme: async function(email) {
            const url = Constants.USERS_GET_GAMME_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const params = {"user_email":email}
            return await this.$request.request_post_api("UserMixin::getUserGamme", url, params, false)
        },

        async setLanguage(lang) {
			const oldLang = this.userLang
			loadLocale(lang)

			Vue.i18n.set(lang)
			this.userLang = lang

			// Le localstorage est toujours nécessaire, au démarrage du logiciel, la bdd n'est pas encore setup
			this.setConfig('lang', lang)

			if (oldLang != lang) {				
				await this.$request.request_post_api(
					"UserMixin::setLanguage",
					Constants.USER_SET_LANG,
					{ lang },
					false
				)
			}

			const bearer = this.getConfig('token')
			this.$request.set_headers(bearer)
		},
		
		/* Gestion admin d'une licence */
		async getLicenceModulesCode() {
			const url = Constants.USER_GET_LICENCE_MODULES_CODE+"?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_get_api("UserMixin::getLicenceModulesCode", url)
		},

		// Retourne les utilisateurs actuels à gérer
		async getUsersToManage(licence_id = 0) {
            const params = { 'licence_id': licence_id}
			const url = this.constructRoute(Constants.USER_GET_USER_TO_MANAGE_LICENCE, params)+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("UserMixin::getUsersToManage", url)
            .catch(error => {
                console.error("UserMixin::getUsersToManage => ERROR", error)
                return null
            })
            return result
		},

        async getGroupsToManage() {
			const url = Constants.USER_GET_GROUP_TO_MANAGE+"?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_get_api("UserMixin::getGroupsToManage", url)
		},

		async setUsersAndGroupsToManage(users, deleted_users, groups, deleted_groups_labels, deleted_groups) {
			const url = Constants.USER_GET_USER_TO_MANAGE+"?licence_key="+Constants.USER_LICENCE_KEY
			const params = { users, deleted_users, groups, deleted_groups_labels, deleted_groups }

			return this.$request.request_post_api("UserMixin::setUsersAndGroupsToManage", url, params, false)
				.catch(error => {
					console.error("UserMixin::setUsersAndGroupsToManage => ERROR", error)
					this.failureToast("toast.info_save_failed")
					return null
				})
		},

        async checkIfAdressMailExists(email) {
            const url = Constants.CHECK_EMAIL_URL+"?licence_key="+Constants.USER_LICENCE_KEY+"&email="+email
            const result = await this.$request.request_get_api("UserMixin::checkIfAdressMailExist", url)
            .catch(error => {
                console.log("UserMixin::checkIfAdressMailExists => ERROR", error)
                return null
            })
            if(result.code_retour == 0) return result.retour
            return
        }
    }
}

export default UserMixin
