const state = {
    isOpen: false,
    isFooterOpen: false,
    isAddOpen: false,
    isNotificationsOpen: false,
    notificationsCount: 0
}

const mutations = {
    open(state) {
        state.isOpen = true
        state.isFooterOpen = false
        state.isNotificationsOpen = false
    },
    close(state) {
        state.isOpen = false
    },
    openFooter(state) {
        state.isFooterOpen = true
        state.isOpen = false
        state.isNotificationsOpen = false
    },
    closeFooter(state) {
        state.isFooterOpen = false
    },
    openNotifications(state) {
        state.isNotificationsOpen = true
        state.isFooterOpen = false
        state.isOpen = false
    },
    closeNotifications(state) {
        state.isNotificationsOpen = false
    },
    updateNotificationsCount(state, count) {
        state.notificationsCount = count
    },
    openAdd(state) {
        state.isAddOpen = true
    },
    closeAdd(state) {
        state.isAddOpen = false
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
