import Constants from 'Constants'
import Store from '@/store'

var ConfigMixin = {
    methods: {
        loadConfig: async function() {
            if (!window.navigator.onLine) {
                return true
            }

            try {
                // On continue d'envoyer la langue pour la rétrocompatibilité mais à enlever à la prochaine publi'
                const url = Constants.GENERAL_CONFIG+"?lang=" + Store.state.user.lang + "&licence_key="+Constants.USER_LICENCE_KEY
                const general_config = await this.$request.request_get_api("ConfigMixin::loadConfig", url)

                this.setConfig('table_config', general_config.retour.table_config)
                // this.setConfig('action_button', general_config.retour.action_button)
                this.setConfig('action_filter', general_config.retour.action_filter)

                this.setConfig('payment_method', general_config.retour.payment_method)
                this.setConfig('payment_bank', general_config.retour.payment_bank)
                this.setConfig('check_deposit_status', general_config.retour.check_deposit_status)
                this.setConfig('user_access', this.$cryptaes.encrypt(general_config.retour.user_access))

                this.setConfig('export_config', general_config.retour.export_config)
                this.setConfig('debug_level', general_config.retour.debug_level)
                this.setConfig('need_reset', general_config.retour.need_reset)
                this.setConfig('need_export', general_config.retour.need_export)
                this.setConfig('lang', general_config.retour.lang || 'fr')
            }
            catch(error) {
                console.error("ConfigMixin::loadConfig => An error occured:", error)
                return false
            }
            return true
        },

        loadHorsetype: async function(type) {
            if (type != "race" && type != "robe" && type != "sexe")
                return null

            let result = null
            await this.axios.get(Constants.CONFIG_HORSE + type + "?lang=" + Store.state.user.lang).then(response => (result = response))

            if (result.status == 200 && result.data.code_retour === 0) {
                this.setConfig(type, result.data.retour)
            }
        },

        loadTableConfig: async function() {
            let table_config = null
            await this.axios.get(Constants.USER_TABLE_CONFIG+"?licence_key="+Constants.USER_LICENCE_KEY).then(response => (table_config = response))

            if(table_config)
                this.setConfig("table_config", table_config.data.retour)
            else
                this.setConfig("table_config", null)
        },

        /* Permet de reload une catégorie précises pour tous les types d'actes (groupe, type et type ref) */
        reloadActesType: async function() {
			return this.$sync.force(true)
        },

        loadExportConfig: async function() {
            let exportPerso = null
            let url = this.constructRoute(Constants.EXPORT_PERSO_GET, {licence_key: ""}) + "?licence_key="+Constants.USER_LICENCE_KEY
            await this.axios.get(url).then(response => (exportPerso = response))
            if(exportPerso)
                this.setConfig("export_config", exportPerso.data.retour)
            else
                this.setConfig("export_config", null)
            return true;
        },

        getConfig: function(type, defaultVal=null) {
            let c = localStorage.getItem(type)
            
            if (c && c != "undefined") { // problème de formattage pour certaines valeurs vides
                return JSON.parse(c)
            }
            return defaultVal
        },

		getAsyncConfig: async function(type, defaultVal=null) {
			return this.getConfig(type, defaultVal)
		},

		getUserConfig: async function(type, defaultVal=null) {
            const config = this.getConfig('user_config')
			return config ? (config[type] || defaultVal) : defaultVal
		},

        setConfig: function(type, value) {
            if(type) {
                localStorage.setItem(type, JSON.stringify(value))
            }

            // Ajouter le type à la liste des configurations utilisateur
            const configList = this.getConfig('config_list', ['token', 'licence_key', 'user_id', 'valid_user', 'tiers_id'])
            if (configList.indexOf(type) === -1) {
                configList.push(type)
                this.setConfig('config_list', configList)
            }
        },

        rmConfigByType: function(type) {
            localStorage.removeItem(type)
        },

        rmConfig: function() {
            const configList = this.getConfig('config_list', [])
            configList.forEach(type => {
                localStorage.removeItem(type)
            })
        },

        getMapBoxKey: function() {
            let mapbox_key = null

            const config = this.getConfig('api_config')
            const mapbox = config.find(conf => conf.apiconfig_type == "MAPBOX")
            if(mapbox) {
                mapbox_key = mapbox.apiconfig_key
            }

            return mapbox_key
        }
	}
}

export default ConfigMixin
