<script>
import Vue from 'vue'
// https://vue-multiselect.js.org/
import Multiselect from 'vue-multiselect'

export default {
    extends: Multiselect,
    name:'e-select',
    props: {
        placeholder: {
            type: String,
            default: () => {
                Vue.prototype.$t('select.placeholder')
            }
        },
        selectLabel: {
            type: String,
            default: () => {
                Vue.prototype.$t('select.selectLabel')
            }
        },
        selectGroupLabel: {
            type: String,
            default: () => {
                Vue.prototype.$t('select.selectGroupLabel')
            }
        },
        selectedLabel: {
            type: String,
            default: () => {
                Vue.prototype.$t('select.selectedLabel')
            }
        },
        deselectLabel: {
            type: String,
            default: () => {
                Vue.prototype.$t('select.deselectLabel')
            }
        },
        deselectGroupLabel: {
            type: String,
            default: () => {
                Vue.prototype.$t('select.deselectGroupLabel')
            }
        },
        sortable: {
            type: Boolean,
            default: true
        },
        sortDesc: {
            type: Boolean,
            default: false
        },
        optionsLimit: {
            type: Number,
            default: 300
        },
        internalSearch: {
            type: Boolean,
            default: true
        },
        groupLabel: {
            type: String,
            default: undefined
        }
    },
    created() {
        if(this.sortable){
            this.sortOptions()
        }
    },
    methods: {
        sortOptions() {
            if (this.options.length === 0) {
                return
            }

            const direction = this.sortDesc ? 'DESC' : 'ASC'
            if (!this.options.isSorted(this.trackBy, direction)) {
                this.options.sortBy(this.trackBy, direction)
            }
        },
        filterOptions (options, search, label, customLabel) {
            return options.filter(option => this.includes(customLabel(option, label), search))
        },
        flattenOptions (values, label) {
            return (options) =>
                options.reduce((prev, curr) => {
                /* istanbul ignore else */
                    if (curr[values] && curr[values].length) {
                        prev.push({
                            $groupLabel: curr[label],
                            $isLabel: true
                        })
                        return prev.concat(curr[values])
                    }
                    return prev
                }, [])
        },
        not (fun) {
            return (...params) => !fun(...params)
        },
        includes (str, query) {
            /* istanbul ignore else */
            if (str === undefined) str = 'undefined'
            if (str === null) str = 'null'
            if (str === false) str = 'false'
            const text = str.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            return text.indexOf(query.trim()) !== -1
        }
    },
    computed: {
        filteredOptions () {
            let search = this.search || ''
            const normalizedSearch = search.toLowerCase().trim()
            search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

            let options = this.options.concat()

            /* istanbul ignore else */
            if (this.internalSearch) {
                options = this.groupValues
                ? this.filterAndFlat(options, normalizedSearch, this.label)
                : this.filterOptions(options, normalizedSearch, this.label, this.customLabel)
            } else {
                options = this.groupValues ? this.flattenOptions(this.groupValues, this.groupLabel)(options) : options
            }

            options = this.hideSelected
            ? options.filter(this.not(this.isSelected))
            : options

            /* istanbul ignore else */
            if (this.taggable && normalizedSearch.length && !this.isExistingOption(normalizedSearch)) {
                if (this.tagPosition === 'bottom') {
                    options.push({ isTag: true, label: search })
                } else {
                    options.unshift({ isTag: true, label: search })
                }
            }

            return options.slice(0, this.optionsLimit)
        },
    },
    watch: {
        options(val) {
            if(this.sortable){
                this.sortOptions()
            }
        },
    }
}
</script>
