import Vue from 'vue'
import { EventBus } from 'EventBus'

export default {
    data() {
        return {

        }
    },
	methods: {
        /* Toasts d'infos */
        successToast(message_trad, trad_params=[]) {
            const params = {
                title: this.getTrad('global.information'),
                body: this.getTrad('toast.info_modif_succes'),
                type: 'success'
            }
            if(message_trad)
                params.body = this.getTrad(message_trad, trad_params)

            EventBus.$emit('Header::displayToast', params)
        },
        failureToast(message_trad, raw = false, trad_params=[]) {
            const params = {
                title: this.getTrad('global.information'),
                body: this.getTrad('toast.info_modif_failed'),
                type: 'danger'
            }

            if(message_trad) {
                if(!raw) {
                    params.body = this.getTrad(message_trad, trad_params)
                }
                else {
                    params.body = message_trad
                }
            }

            EventBus.$emit('Header::displayToast', params)
        },
        infoToast(message_trad, trad_params=[]) {
            const params = {
                title: Vue.i18n.translate('global.information'),
                body: Vue.i18n.translate('toast.info_action_impossible'),
                type: 'info'
            }
            if(message_trad)
                params.body = Vue.i18n.translate(message_trad, trad_params)

            EventBus.$emit('Header::displayToast', params)
		},
		notificationToast(title, body) {
			const params = {
				title: title,
				body: body,
				toaster: 'b-toaster-bottom-right',
				solid: true,
				appendToast: true,
				type: 'primary'
			}

			EventBus.$emit('Header::displayToast', params)
		}
	}
}