// Variables globales
import Vue from 'vue'
import Store from '@/store/index'
import Constants from 'Constants'
import { EventBus } from 'EventBus'
import _defaults from 'lodash/defaults'
import _cloneDeep from 'lodash/cloneDeep'

export default class Request {

	constructor() {
        this.cancelFns = {}
	}

	getOptions(opts) {
		return _defaults(opts, {
			autoCatch: true,
            sync: false,
            syncOptional: false,
            withDetails: false,
        })
	}

	set_headers(bearer_token) {
        if(bearer_token != undefined) {
			Vue.axios.defaults.headers['Authorization'] = 'Bearer ' + bearer_token
		  	Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearer_token
        }

		Vue.axios.defaults.headers['Access-Control-Allow-Origin'] = Constants.BASE_API_URL
        Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = Constants.BASE_API_URL
		Vue.axios.defaults.headers.common['Accept-Language'] = Store.state.user.lang

        if(process.env.VUE_APP_BUILD_VERSION.startsWith('v')) {
            Vue.axios.defaults.headers['Version'] = process.env.VUE_APP_BUILD_VERSION
            Vue.axios.defaults.headers.common['Version'] = process.env.VUE_APP_BUILD_VERSION
        }
	}

	reset_header() {
		Vue.axios.defaults.headers['Authorization'] = ''
	}

	cancelRequests() {
        Object.keys(this.cancelFns).forEach(url => {
            if (this.cancelFns[url]) {
                this.cancelFns[url]()
            }
        })
        this.cancelFns = {}
    }

    async before_request(caller, url, method) {

    }

    async after_request(caller, url, method, response, opts={}) {
		if (Array.isArray(opts.sync)) {
			await Vue.prototype.$sync.runRequiredWhishlist(opts.sync)
		}
		if (Array.isArray(opts.syncOptional)) {
			await Vue.prototype.$sync.runOptionalWhishlist(opts.syncOptional)
		}
		if (opts.sync === true) {
			await Vue.prototype.$sync.force(opts.syncOptional)
		}

        return response
    }

    catch_errors (err) {
        if (err.response) {
            if(err.response.status === 401) {
                if (err.response.data && err.response.data.error !== 'Invalid credentials') {
                    // EventBus.$emit('App::forceDelogUser')
                    let params = {
                        title: Vue.i18n.translate('global.information'),
                        body: Vue.i18n.translate('toast.contact_equideclic_evolution'),
                        type: 'info'
                    }

                    if(err.response.data == "Vous n'avez plus assez de jetons pour effectuer cette requête") {
                        params.body = err.response.data 
                    }

                    EventBus.$emit('Header::displayToast', params)
                }
            }
            else if (err.response.status === 503) {
                EventBus.$emit('App::failureToast', 'error.TUN')
            } 
            else if (err.response.status == 406) {
                EventBus.$emit('App::failureToast', err.response.data.message)

                navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`).then(registration => {
                    registration.update();
                    EventBus.$emit('SW::updateFound')
                    EventBus.$on('SW::updated', () => {
                        window.location.reload()
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 5000);
                })
            }
            else if (err.response.status == 403) {
                // si j'ai une 403 j'affiche un toast d'erreur et je redirige après 1s (le temps d'afficher le toast)
                EventBus.$emit('App::failureToast', err.response.data.message)
                setTimeout(() => {
                    window.location.replace("/");
                }, 1000)
            }
            else if (err.response.data) {
				const blacklist = ['CLDNHACA']

                if (err.response.data.type === 'ApiException' && blacklist.indexOf(err.response.data.code_retour) === -1) {
                    EventBus.$emit('App::failureToast', err.response.data.message)
                }
            } 
        }

        throw err
    }

    parseError (err) {
        console.error(err)
        let errMessage = err.message

        if (err.response && err.response.data) {
            if (err.response.status == 401) {
                errMessage = Vue.i18n.translate('compte.bad_identifiants')
            } else if (err.response.data.code_retour === 'PM') {
                errMessage = err.response.data.retour
            } else if(err.response.data.message) {
                errMessage = err.response.data.message
            } else {
                errMessage = Vue.i18n.translate(`error.${err.response.data.code_retour}`)
            }
        }

        throw new Error(errMessage)
    }

    async request_get_api(caller, url, cancellable = true, opts={}) {
		opts = this.getOptions(opts)
        await this.before_request(caller, url, 'GET')
    
        let promise = Vue.axios.get(url, {
            cancelToken: new  Vue.axios.CancelToken((c) => {
                if (cancellable) {
                    this.cancelFns[url] = c
                }
            })
        })
        .then(res => {
            return this.after_request(caller, url, 'GET', res, opts)
        })
        .finally(() => {
            delete this.cancelFns[url]
		})

		if (opts.autoCatch) {
			promise.catch(this.catch_errors)
		}

		const response = await promise

        return new Promise((resolve, reject) => {
            try {
                if (response.status == 200) {
                    resolve(response.data)
                }
            }
            catch (error) {
                reject(error, error.response)
            }
        })
    }

    async request_post_api(caller, url, params, prefixe=true, opts={}) {
		opts = this.getOptions(opts)
        await this.before_request(caller, url, 'POST')
    
		let params_post = {}

		if(prefixe)
			params_post = { records: params }
		else
			params_post = params

        return new Promise((resolve, reject) => {
            let promise = Vue.axios.post(url, params_post)
            .then(res => {
                return this.after_request(caller, url, 'POST', res, opts)
            })
            .then(function (response) {
                resolve(response.data)
			})

			if (opts.autoCatch) {
				promise.catch(this.catch_errors)
			}

            promise.catch(function (error) {
                if(opts.withDetails) {
                    reject(error.response)
                }
                else{
                    reject(error, error.response)
                }
            })
        })
    }

    async request_delete_api(caller, url, params={}, opts={}) {
		opts = this.getOptions(opts)
        await this.before_request(caller, url, 'DELETE')
    
        return new Promise(async (resolve, reject) => {
            let promise = Vue.axios.delete(url, {data: params})
            .then(res => {
                return this.after_request(caller, url, 'DELETE', res, opts)
            })
            .then(function (response) {
                resolve(response.data)
			})

			if (opts.autoCatch) {
				promise.catch(this.catch_errors)
			}

            promise.catch(function (error) {
                reject(error, error.response)
			})
        })
    }

	async request_get_file_api(caller, url, params={}, opts={}) {
		opts = this.getOptions(opts)
        await this.before_request(caller, url, 'GET_FILE')
    
		return new Promise((resolve, reject) => {
			let promise = Vue.axios.get(url, {
				header: Vue.axios.defaults.headers.common,
                responseType: 'blob',
                params
			})
            .then(res => {
                return this.after_request(caller, url, 'GET_FILE', res, opts)
            })
			.then(response => {
				resolve(response.data)
			})

			if (opts.autoCatch) {
				promise.catch(this.catch_errors)
			}

			promise.catch(error => {
				console.error("Request::request_get_file_api => There was an error while requesting " + url + " : " + error + " -- Stack trace : " + caller)
				reject(error)
			})
		})
	}

	async request_post_file_api(caller, url, files) {
		let formData = new FormData()

		files.forEach(file => {
			formData.append(file.name, file.content)
		})

		return new Promise((resolve, reject) => {
			Vue.axios.post(url, formData, {
				headers: _defaults(_cloneDeep(Vue.axios.defaults.headers.common), {
                    'Content-Type': 'multipart/form-data'
				})
			})
			.then(response => {
				resolve(response)
			})
			.catch(error => {
                this.catch_errors(error)
				console.error("Request::request_post_file_api => There was an error while requesting " + url + " : " + error + " -- Stack trace : " + caller)
				reject(error)
			})
		})
	}
}
