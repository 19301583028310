<template>
    <div class="col-auto pr-md-4">
        <a
            href="#"
            class="btn btn-link"
            @click.prevent="goBack()"
        >
            <font-awesome-icon :icon="['fal', 'long-arrow-left']" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'back-button',
    props: {
        customAction: { type: Function, default: null }
    },
    methods: {
        goBack () {
            if(this.customAction && typeof(this.customAction) == 'function') {
                return this.customAction()
            }
            window.history.back()
        }
    }
}
</script>