import { Mark } from 'tiptap';
import { markInputRule, updateMark, removeMark } from 'tiptap-commands';

/**
 * FontSize - Extends the built in Mark from tiptap allowing
 * the editor to <drumroll> set the font-size.
 */
export default class FontSize extends Mark {
	get name() {
		return 'fontSize'
	}

	get defaultOptions () {
		return {
			unit: 'em', // em / px / %
		}
	}
  
	get schema() {
		return {
			attrs: {fontSize: { default: `1${this.options.unit}`}},
			parseDOM: [{
				style: 'font-size',
				getAttrs: value => value.indexOf(this.options.unit) !== -1 ? { fontSize: value } : '',
			}],
			toDOM: mark => {
                let inline_tyle = ''

                // Si on a l'unité données avec
                if(mark.attrs.fontSize && typeof mark.attrs.fontSize == "string" && mark.attrs.fontSize.indexOf(this.options.unit) > -1) {
                    inline_tyle = `font-size: ${mark.attrs.fontSize}`
                }
                else {
                    inline_tyle = `font-size: ${mark.attrs.fontSize}${this.options.unit}`
                }

                return ['span', {style: inline_tyle}, 0]
            },
		}
	}

	commands({type}) {
		return attrs => {
			if ((attrs.fontSize) && (attrs.fontSize != `1${this.options.unit}`)) {
				return updateMark(type, attrs)
			}
			return removeMark(type)
		}
	}
  
	inputRules({type}) {
		return [ markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type) ]
	}
}