import Constants from 'Constants'
import Common from 'GroomyRoot/assets/js/common.js'
import _cloneDeep from 'lodash/cloneDeep';

var MouvementMixin = {
    data() {
        return {

        }
    },

	methods: {
        /* Retourne la liste des chevaux appartenant à cette licence, avec le nom des parents, et photo de profil */
		getMouvements: async function() {
            const url = Constants.MOUVEMENTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Mouvement:getMouvements", url)
            .catch(error => {
                console.error("MouvementMixin::getMouvements => ERROR", error)
                return null
            })

            if(result && result.retour) return await this.formatMouvementData(result.retour)
            return null
        },

        /* Retourne les détails du cheval à partir de son id - race, robe, sexe, age, photo de profil, nom, prénom, ... */
        getMouvementById: async function(mouvement_id) {
            const params = { id: mouvement_id }
            const url = this.constructRoute(Constants.MOUVEMENTS_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Mouvement:getMouvementById", url)
            .catch(error => {
                console.error("MouvementMixin::getMouvementById => ERROR", error)
                return null
            })

            if(result && result.retour) return await this.formatMouvementData(result.retour)
            return null
        },

        // Retourne le tout dernier mouvement affecté au cheval
        getLastMouvement: async function(horse_id) {
            let url = Constants.HORSE_DERNIER_MV_URL.replace(":id", horse_id)
            url += "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("Mouvement:getLastMouvement", url)
            .catch(error => {
                console.error("MouvementMixin::getLastMouvement => ERROR", error)
                return null
            })

            if(result && result.retour) return result.retour
            return null
        },

        // Retourne le mouvement précédent, par rapport à un mouvement id, pour un cheval donné
        getPreviousMouvement: async function(horse_id, mouv_id) {
            const params = { 'mouvement_id': mouv_id, 'horse_id': horse_id }
            const url = this.constructRoute(Constants.MOUVEMENTS_PREVIOUS, params) + "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("Mouvement:getPreviousMouvement", url)
            .catch(error => {
                console.error("MouvementMixin::getPreviousMouvement => ERROR", error)
                return null
            })

            if(result && result.retour) return result.retour
            return
        },

        getYearOfMouvements: async function(horse_id) {
            let url = Constants.MOUVEMENTS_ANNEE_URL
            if (horse_id) {
                url += "/" +  horse_id
            }

            const result = await this.$request.request_get_api("Mouvement::getYearOfMouvements", url+"?licence_key="+Constants.USER_LICENCE_KEY)
            .catch(error => {
                console.error("MouvementMixin::getYearOfMouvements => ERROR", error)
                return null
            })

            if(result && result.retour) return result.retour
            return null
		},

        getLieuMouvements: async function() {
            const result = await this.$request.request_get_api("Mouvement::getLieuMouvements", Constants.MOUVEMENTS_LABEL_URL+"?licence_key="+Constants.USER_LICENCE_KEY)
            .catch(error => {
                console.error("MouvementMixin::getLieuMouvements => ERROR", error)
                return null
            })

            if(result && result.retour) return result.retour
            return null
        },

        /* OPERATIONS CRUD */
        /* Ajout d'un mouvement sur un cheval */
        addMouvement: async function(infos) {
            let res = 0
            const url = Constants.MOUVEMENTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY

            await this.$request.request_post_api("Mouvement::addMouvement", url, infos, false)
            .then(response => {
                if(response.code_retour === 0)
                    res = response.retour.mouvement_id
            })
            .catch(e => (res = 0))

            return res
        },

        updateMouvement: async function(mouvement_id, infos) {
            let res = 0
            const url = Constants.MOUVEMENTS_URL +"/"+ mouvement_id +"?licence_key="+ Constants.USER_LICENCE_KEY

            await this.$request.request_post_api("Mouvement::updateMouvement", url, infos, false)
            .then(response => {
                if(response.code_retour === 0)
                    res = response.retour.mouvement_id
            })
            .catch(error => (res = 0))

            return res
        },

        reloadMouvementsListe() { // à mon avis, ça c'est du thomas
            this.loadMouvementsListe(true)
        },

        /* Action : Suppression de mouvements */
        applyDeleteMouvements(data) {
            this.table_busy = true
            const mouvements_ids = data.map((mouvement) => { return mouvement.mouvement_id })

            this.deleteMouvement(mouvements_ids)
            .then(() => {
                this.successToast()

                // On supprime les mouvements en local (juste pour l'affichage)
                const mouvements_deleted = this.mouvements.filter(mouvement => {
                    return (mouvements_ids.indexOf(mouvement.mouvement_id) > -1 ) ? mouvement : null
                })

                for(let index in mouvements_deleted) {
                    const mouvement_index = this.mouvements.indexOf(mouvements_deleted[index])

                    if(mouvement_index > -1) {
                        this.mouvements.splice(mouvement_index, 1)
                    }
                }
            })
            .catch(e => {
                this.failureToast()
            })
            .finally(() => {
                this.reloadMouvementsListe()
            })
        },

        /* On passe le mouvement_valide à 0, sans supprimer de la bdd */
        deleteMouvement: async function(mouvement_id) {
            return await this.$request.request_delete_api("Mouvement::deleteMouvement", Constants.MOUVEMENTS_URL + "/" +  mouvement_id +"?licence_key="+ Constants.USER_LICENCE_KEY, {})
        },

        /* Fonction utilitaires */
        formatMouvementData: async function(items) {
            let date

            await Common.asyncForEach(items, (item) => {
                if(item.mouvement_date != undefined && item.mouvement_date != "0000-00-00") {
                    date = new Date(item.mouvement_date)
                    item["isActive"] = false
                    item["mouvement_date"] = date
                    item["mouvement_date_year"] = date.getFullYear()
                    item["mouvement_date_month_without_zero"] = date.getMonth()+1
                    item["mouvement_date_month"] = ('0' + (date.getMonth()+1)).slice(-2)
                    item["mouvement_date_day"] = ('0' + date.getDate()).slice(-2)
                    item["mouvement_date_month_label"] = this.getTrad("mois."+item["mouvement_date_month_without_zero"])
                    item["mouvement_date_month_label_court"] = this.getTrad("mois_court."+item["mouvement_date_month_without_zero"])
                }
                else {
                    item["mouvement_date"] = ''
                    item["mouvement_date_year"] = 0
                    item["mouvement_date_month"] = 0
                    item["mouvement_date_day"] = 0
                }

                if(item.mouvement_id)
                    item.id = item.mouvement_id
                else
                    item.id = item.lieu_id

                if(item.mouvement_type == 1)
                    item.class = "entree"
                else
                    item.class = "sortie"

                item.isActive = false
            })

            return items
        },

        /* Tout le mécanisme de sauvegarde d'un mouvement et de son lieu */
        processMouvOperations: async function(datas, checked_horses, mouvement_id=0, first_mouv=false) {
            // permet de lock l'update de la résidence si elle a déjà été faite plus tot
            // cas de modif d'un mouv, si le lieu a été changé via le form, on ajoute un lieu, qu'on considère comme nouvelle résidence
            let has_already_updated_residence = false

            try {
                // On extrait les éventuels lieux à enregistrer
                const lieux = datas.filter(data => data.type == 'lieu')
                let lieux_saved = []

                if(lieux.length > 0) {
                    let tab_promises_lieux = []
                    lieux.forEach(lieu => {
                        // eslint-disable-next-line no-async-promise-executor
                        tab_promises_lieux.push(new Promise(async (res, rej) => {
                            const lieu_tag = _cloneDeep(lieu.lieu_tag)

                            let inserted = await this.saveLieu({
                                lieu_label  : lieu.lieu_label,
                                lieu_adresse: lieu.lieu_adresse,
                                lieu_cp     : lieu.lieu_cp,
                                lieu_ville  : lieu.lieu_ville,
                                lieu_type   : lieu.lieu_type
                            })
                            .catch(e => rej(e))
                            inserted.retour.lieu_tag = lieu_tag
                            res(inserted.retour)
                        }))
                    })

                    lieux_saved = await Promise.all(tab_promises_lieux)
                }

                // On extrait les provenances, qui doivent être inscrites avant les mouvements
                const provenances = datas.filter(data => data.type == 'provenance')
                if(provenances.length > 0) {
                    let tab_promises_provenances = []

                    // Si on a un mouvement_id (édition de mouvement) et une provenance, c'est qu'on édite la premiere entrée du cheval
                    if(mouvement_id) {
                        // On commence par récupérer l'id de la provenance initiale
                        const prov_init = await this.getInitialProvenance(checked_horses[0])

                        if(prov_init.mouvement_id) {
                            const mouv_update = provenances[0]
                            await this.updateMouvement(prov_init.mouvement_id, {
                                mouvement_label  : mouv_update.mouvement_label,
                                mouvement_date   : mouv_update.mouvement_date,
                                mouvement_raison : mouv_update.mouvement_raison,
                                mouvement_type   : mouv_update.mouvement_type,
                                mouvement_horse  : checked_horses[0],
                            })
                        }
                    }
                    // Sinon, on ajoute de manière classique
                    else {
                        const mouvement_lieu = lieux_saved.find(lieu => lieu.lieu_tag === 'provenance')
                        const mouvement_lieu_id = mouvement_lieu ? mouvement_lieu.lieu_id : null

                        checked_horses.forEach(horse => {
                            provenances.forEach(mouvement => {
                                // eslint-disable-next-line no-async-promise-executor
                                tab_promises_provenances.push(new Promise(async (res, rej) => {
                                    const horse_id = JSON.stringify(horse)
                                    const local_mouv = this.deppCloneObj(mouvement)

                                    // Si provenance initiale
                                    if(local_mouv.mouvement_date == '0000-00-00') {
                                        local_mouv.mouvement_lieu = null
                                    }
                                    // Sinon, on a un lieu correspondant
                                    else {
                                        local_mouv.mouvement_lieu = mouvement_lieu_id ? mouvement_lieu_id : local_mouv.mouvement_lieu
                                    }

                                    await this.addMouvement({
                                        mouvement_label  : local_mouv.mouvement_label,
                                        mouvement_adresse: local_mouv.mouvement_adresse,
                                        mouvement_cp     : local_mouv.mouvement_cp,
                                        mouvement_ville  : local_mouv.mouvement_ville,
                                        mouvement_date   : local_mouv.mouvement_date,
                                        mouvement_raison : local_mouv.mouvement_raison,
                                        mouvement_type   : local_mouv.mouvement_type,
                                        mouvement_lieu   : local_mouv.mouvement_lieu,
                                        mouvement_horse  : JSON.parse(horse_id)
                                    })
                                    .catch(e => rej(e))

                                    res()
                                }))
                            })
                        })
                        await Promise.all(tab_promises_provenances)
                    }
                }

                // On extrait le(s) mouvement(s) à enregistrer
                const mouvements = datas.filter(data => data.type == 'mouvement')
                if(mouvements.length > 0) {
                    // Si le mouvement fait référence à un lieu tout juste inséré, on récupère le lieu_id
                    const mouvement_lieu = lieux_saved ? lieux_saved.find(lieu => lieu.lieu_tag === 'mouvement') : null
                    const mouvement_lieu_id = mouvement_lieu ? mouvement_lieu.lieu_id : null

                    /* Si on est sur une update d'un mouvement */
                    if(mouvement_id) {
                        const mouv_update = mouvements[0]
                        // Si on a un id de nouveau lieu, on le prend. Sinon on garde celui d'origine
                        mouv_update.mouvement_lieu = mouvement_lieu_id ? mouvement_lieu_id : mouv_update.mouvement_lieu

                        await this.updateMouvement(mouvement_id, {
                            mouvement_label  : mouv_update.mouvement_label,
                            mouvement_adresse: mouv_update.mouvement_adresse,
                            mouvement_cp     : mouv_update.mouvement_cp,
                            mouvement_ville  : mouv_update.mouvement_ville,
                            mouvement_date   : mouv_update.mouvement_date,
                            mouvement_raison : mouv_update.mouvement_raison,
                            mouvement_type   : mouv_update.mouvement_type,
                            mouvement_lieu   : mouv_update.mouvement_lieu,
                            mouvement_horse  : checked_horses[0],
                        })

                        // // Si à la modif du mouvement, le user a choisit une autre résidence, on update la résidence actuelle du cheval
                        // if(!has_already_updated_residence && mouv_update.mouvement_lieu && mouv_update.mouvement_type == 1) {
                        //     await this.updateHorseResidence(checked_horses[0], mouv_update.mouvement_lieu)
                        // }

                        return true
                    }

                    /* Si on est sur un ajout de mouvement(s) */
                    // On regarde si on a un mouvement retour - on peut dorénavent avoir une date retour pour chaque cheval
                    const mouv_retour = datas.filter(data => data.type == 'mouvement_retour')
                    let previous_mouv = []
                    let mouv_retour_prs = []

                    if(mouv_retour.length > 0) {
                        // Pour chaque cheval, on construit le mouvement à enreigstrer
                        checked_horses.forEach(horse_id => {
                            mouv_retour_prs.push(new Promise(async (res, rej) => {
                                // const previous_mouv_tab = await this.getHorseActualResidence(this.deppCloneObj(horse_id)).catch(e => { rej(e) })
                                const previous_mouv_tab = await this.getHorseLastResidence(this.deppCloneObj(horse_id)).catch(e => { rej(e) })

                                if(Array.isArray(previous_mouv_tab)) {
                                    previous_mouv.push(previous_mouv_tab[0])
                                }
                                else {
                                    previous_mouv.push(previous_mouv_tab)
                                }
                                res()
                            }))
                        })
                    }
                    await Promise.all(mouv_retour_prs)
                    .catch(e => {
                        console.log("MouvementMixin::processMouvOperations => An error occured on mouv_retour_prs promises", e)
                        return false
                    })

                    // Chemin classique - pour chaque cheval séléctionné, on enregistre le mouvement => à transformer de tab de promises ? question des données à figer dans la boucle
                    let tab_promises_mouv = []
                    const mouvement = mouvements[0]
                    mouvement.mouvement_lieu = mouvement_lieu_id ? mouvement_lieu_id : mouvement.mouvement_lieu

                    checked_horses.forEach(horse => {
                        // eslint-disable-next-line no-async-promise-executor
                        tab_promises_mouv.push(new Promise(async (res, rej) => {
                            const horse_id = JSON.stringify(horse)
                            const local_mouv = this.deppCloneObj(mouvement)

                            await this.addMouvement({
                                mouvement_label  : local_mouv.mouvement_label,
                                mouvement_adresse: local_mouv.mouvement_adresse,
                                mouvement_cp     : local_mouv.mouvement_cp,
                                mouvement_ville  : local_mouv.mouvement_ville,
                                mouvement_date   : local_mouv.mouvement_date,
                                mouvement_raison : local_mouv.mouvement_raison,
                                mouvement_type   : local_mouv.mouvement_type,
                                mouvement_lieu   : local_mouv.mouvement_lieu,
                                mouvement_horse  : JSON.parse(horse_id)
                            })
                            .catch(e => rej(e))

                            // Si c'est un mouvement vers une résidence (de type 1) on assigne le cheval à cette résidence
                            if(!has_already_updated_residence && local_mouv.mouvement_lieu && local_mouv.mouvement_type == 1 && first_mouv) {
                                await this.updateHorseResidence(JSON.parse(horse_id), local_mouv.mouvement_lieu)
                                .catch(e => rej(e))
                            }
                            res()
                        }))
                    })

                    // Si on a récupéré un lieu de retour précédemment, on enregistre un mouvement retour à ce lieu, et à la date retour donnée
                    // On aura ce cas uniquement dans un ajout de mouvement pour un seul cheval. Trop complexe à gérer si plusieurs chevaux
                    if((Object.keys(previous_mouv)).length > 0) {
                        const retour_raison = this.getTrad("mouvement.mouvement_raison_retour_default_value") + mouvements[0].mouvement_raison

                        previous_mouv.forEach(mouv => {
                            tab_promises_mouv.push(this.addMouvement({
                                mouvement_label  : mouv.lieu_label,
                                mouvement_adresse: mouv.lieu_adresse,
                                mouvement_cp     : mouv.lieu_cp,
                                mouvement_ville  : mouv.lieu_ville,
                                mouvement_date   : mouv_retour[0].date_retour,
                                mouvement_raison : retour_raison,
                                mouvement_type   : 1,
                                mouvement_horse  : mouv.mouvement_horse,
                                mouvement_lieu   : mouv.lieu_id
                            }))
                        })
                    }

                    return await Promise.all(tab_promises_mouv)
                }
            }
            catch(e) {
                console.log("MouvementMixin::processMouvOperations => Error while processing", e)
                return false
            }

            return true
        }
	}
}

export default MouvementMixin
