import RootCommon from 'GroomyRoot/assets/js/common.js'
import Store from '@/store/index'

export default class Common extends RootCommon
{
    // Override getCurrency (pas d'offline)
	static getCurrency(currencyCode) {
		return {
			currency_code: "EUR",
			currency_decimal_digits: 2,
			currency_id: 3,
			currency_name: "Euro",
			currency_name_plural: "euros",
			currency_symbol: "€"
		}
	}

    static priceFormat(val, currencyCode='EUR', withSymbol=false) {
		const currency = Common.getCurrency(currencyCode)
		const lang = Store.state.user.lang
		val = val.toString().replace(',', '.')

		const formatter = new Intl.NumberFormat(lang, {
			style: withSymbol ? 'currency' : 'decimal',
			currency: currencyCode,
			minimumFractionDigits: currency.currency_decimal_digits,
			maximumFractionDigits: currency.currency_decimal_digits
		})

		return formatter.format(val)
	}
}

const getSubDomainName = () => {
    const clients = [
        'monceaux',
        'gfe',
        'pony-stallion',
        'eric-levallois',
		'huntervalley',
		'genesdiffusion',
		'etalons-dressage',
		'equideclic',
		'croissance-trot',
		'france-etalons',
		'demo-clients',
		'beligneuxleharas',
		'haras-degravelotte'
    ]

    return clients.find((t) => (window.location.hostname.includes(t))) || 'gfe'
}

const clientCustomRedirects = () => {
    return {
        'monceaux': {
            'horseListe': 'effectif',
            'HorseFicheNotes': 'HorseFeed',
            'notes': 'feed'
        }
    }
}

export { getSubDomainName, clientCustomRedirects }
