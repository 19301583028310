'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'cf';
var iconName = 'dg';
var width = 15.823;
var height = 16;
var ligatures = [];
var unicode = 'e000';
var svgPathData = ['M11.8,6.891V9.412h1.029V11.64c0,1.277-.248,1.843-.975,1.843-.6,0-.936-.388-.936-1.446V4.075c0-1.013.252-1.512.936-1.512.83,0,.922.7.922,1.451v.855h3.008v-.7C15.779,2.6,15.419,0,11.841,0a3.473,3.473,0,0,0-3.8,2.952C7.62.946,6.376,0,3.784,0H0V16H3.993C6.558,16,7.6,14.8,8,13.128,8.257,14.86,9.073,16,10.989,16a2.452,2.452,0,0,0,2.449-1.384,8.74,8.74,0,0,0,.139,1.151h2.246V6.891ZM5.088,11.485c0,1.249-.2,2.035-1.407,2.035h-.6V2.495h.576c1.165,0,1.43.643,1.43,1.825Z'];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData,
  ]};

exports.cfDG = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
