
import Vue from 'vue'
import { getSubDomainName } from "@/assets/js/common.js"
import RootToolsMixin from 'GroomyRoot/mixins/Tools.js'

export default Vue.util.mergeOptions(RootToolsMixin, {
    methods: {
        
    },
    computed: {
    	subDomainName() {
    		return getSubDomainName()
    	}
    }
})