export default [
	'check',
	'compte',
	'fonction',
	'gynecologie',
	'invoice',
	'marketplace',
	'menu',
	'model',
    'monte',
	'notes',
	'payment',
	'pax',
	'storage',
	'table',
	'toast',
	'trigger',
	'user_management',
	'types/lieu_type',
	'types/modules_code'
]