const state = {
    hasLoadConfig: false,
    tabs: []
}

const mutations = {
    ackLoadConfig(state) {
        state.hasLoadConfig = true
    },
    setAuthorizedTabs(state, tabs) {
        state.tabs = tabs
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
