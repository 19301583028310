import aesjs from 'aes-js'
import Constants from 'Constants'

export default class Cryptaes
{
	constructor() {
        this.key_aes = []
        this.counter_encode = 2
		this.generateKeyTab()
	}

    encrypt(data) {
        const aes_obj = new aesjs.ModeOfOperation.ctr(this.key_aes, new aesjs.Counter(this.counter_encode))
        const data_bytes = aesjs.utils.utf8.toBytes(data)
        const encrypted = aes_obj.encrypt(data_bytes)
        return aesjs.utils.hex.fromBytes(encrypted)
    }

    decrypt(data) {
		if(!data) return ""

        const aes_obj = new aesjs.ModeOfOperation.ctr(this.key_aes, new aesjs.Counter(this.counter_encode))
        const data_bytes = aesjs.utils.hex.toBytes(data)
        const decrypted = aes_obj.decrypt(data_bytes)
        return aesjs.utils.utf8.fromBytes(decrypted)
    }

    /* Sert à générer une key que l'on pourra mettre manuellement dans le .env */
    generateKeyTab() {
        const pass_phrase = process.env.VUE_APP_AES_PASSPHRASE
        const tab_letters = pass_phrase.split('')

        let key_tab = []
        tab_letters.forEach(key => {
            key_tab.push(key.charCodeAt(0))
        })

		Constants.CRYPTAES_TAB_KEYS = key_tab
		this.key_aes = key_tab
    }
}
