<!-- https://vcalendar.io/ -->
<template>
	<date-picker
		v-model="model"
		:mode="mode"
		is24hr
		:locale="lang"
		color="pink"
		:popover="popover"
		:min-date="new Date(0)"
		:max-date="new Date(4133894400000) /* 31 Dec 2100 */"
		:attributes='attributes'
	>
		<template v-slot="{ inputValue, inputEvents }">
			<input
				v-on="inputEvents"
				type="text"
				class="form-control"
				:disabled="isDisabled"
				:value="inputValue"
			/>
		</template>
	</date-picker>
</template>


<script>
// https://vcalendar.io/attributes.html
import Constants from 'Constants'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
	components: {
		'date-picker': DatePicker
	},
	name:'e-datepicker',
	props: {
		value:{
			type: Date,
			default: () => (new Date())
		},
		mode: {
			type: String,
			default: () => ('date')
		},
		popover: {
			type: Object,
			default: () => ({
				visibility: 'focus'
			})
		},
		disabled: {
			type: Boolean,
			default: () => ( false )
		}
	},
	data(){
        return {
            attributes: [{
				key: 'today',
				dates: new Date(),
				highlight: {
					color: 'pink',
					fillMode: 'outline',
				},
			}]
        }
    },
	computed: {
		model:{
			get () {
				return this.value
			},
			set (val) {
				this.$emit('input', val)
			}
		},
		isDisabled(){
			return this.disabled
		},
		lang(){
			return this.$store.state.user.lang
		}
	}
}
</script>
