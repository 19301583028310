<template>
	<div class="row">
		<div
			:class="{
				'col-12' : !showSuggestions,
				'col-8': showSuggestions,
				'editor': true
			}"
		>
			<editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
				<div class="menubar row align-items-center no-gutters">
					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.bold() }"
						v-on:click="commands.bold"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.bold')"
					>
						<font-awesome-icon :icon="['far', 'bold']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.italic() }"
						v-on:click="commands.italic"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.italic')"
					>
						<font-awesome-icon :icon="['far', 'italic']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.strike() }"
						v-on:click="commands.strike"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.strikethrough')"
					>
						<font-awesome-icon :icon="['far', 'strikethrough']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.underline() }"
						v-on:click="commands.underline"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.underline')"
					>
						<font-awesome-icon :icon="['far', 'underline']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.heading({ level: 1 }) }"
						v-on:click="commands.heading({ level: 1 })"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.h1')"
					>
						<font-awesome-icon :icon="['far', 'h1']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.heading({ level: 2 }) }"
						v-on:click="commands.heading({ level: 2 })"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.h2')"
					>
						<font-awesome-icon :icon="['far', 'h2']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.heading({ level: 3 }) }"
						v-on:click="commands.heading({ level: 3 })"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.h3')"
					>
						<font-awesome-icon :icon="['far', 'h3']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.bullet_list() }"
						v-on:click="commands.bullet_list"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.bullet_ls')"
					>
						<font-awesome-icon :icon="['far', 'list-ul']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.ordered_list() }"
						v-on:click="commands.ordered_list"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.ordered_ls')"
					>
						<font-awesome-icon :icon="['far', 'list-ol']" />
					</button>

					<button
						class="menubar__button"
						v-on:click="commands.horizontal_rule"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.horizontal_rule')"
					>

						<font-awesome-icon :icon="['far', 'horizontal-rule']" />
					</button>

					<button
						class="menubar__button"
						v-on:click="commands.undo"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.undo')"
					>
						<font-awesome-icon :icon="['far', 'undo']" />
					</button>

					<button
						class="menubar__button"
						v-on:click="commands.redo"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.redo')"
					>
						<font-awesome-icon :icon="['far', 'redo']" />
					</button>

					<button
						class="menubar__button"
						v-on:click="showImageBrowser(commands.image)"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.image')"
					>
						<font-awesome-icon :icon="['far', 'camera']" />
						<input ref="imageInput" type="file" accept="image/jpg,image/png,image/jpeg" class="d-none" @change="onImageSelection" />
					</button>
					

					<button
						class="menubar__button"
						v-on:click="insertVariable"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.variable')"
					>
						<font-awesome-icon :icon="['far', 'brackets-curly']" />
					</button>

					<b-dropdown
						variant="transparent-light"
						size="sm"
						lazy
						right
						class="p-0"
						v-b-tooltip.bottom
						:title="$t('editor.tooltips.blocks')"
					>
						<template v-slot:button-content>
							<font-awesome-icon :icon="['far', 'infinity']" size="lg" />
						</template>

						<b-dropdown-item
							v-for="block in flattenBlocks"
							:key="block.id"
							@click="commands.content_block({
								name: block.id,
								title: block.name
							})"
						>
							{{ $t(block.name) }}
						</b-dropdown-item>
					</b-dropdown>

					<!-- TextSize -->
					<b-button :id="'popover-font-size-'+uuid" class="menubar__button" @click="$refs[textSizePopoverRef].$emit('open')">
						<font-awesome-icon :icon="['fas', 'text-size']" />
					</b-button>
					<b-popover :ref="textSizePopoverRef" :target="'popover-font-size-'+uuid" placement="bottom">
						<template #title>{{ $t('editor.tooltips.font_size') }}</template>
						<b-form-input v-model="textSize" type="range" min="8" max="50" step=1></b-form-input>
						<div class="font_size">{{ textSize }}</div>
						<div class="row no-gutters mt-2">
							<div class="col-auto pr-1">
								<button class="btn-block btn btn-secondary" @click="closeFontSizePopover()"><font-awesome-icon :icon="['fal', 'times']" /> {{ $t('editor.cancel') }}</button>
							</div>
							<div class="col-auto pl-1">
								<button class="btn-block btn btn-primary" @click="applyTextSize(commands)"><font-awesome-icon :icon="['fal', 'save']" /> {{ $t('editor.apply') }}</button>
							</div>
						</div>
					</b-popover>

					<!-- TextColor -->
					<button
						:id="'popover-font-color-'+uuid"
						class="menubar__button"
						:class="{ 'is-active': isActive.textcolor({ color: 'red' }) }"
						@click="showFontColorPopover()"
					>
						<font-awesome-icon :icon="['fas', 'tint']" />
					</button>

					<b-popover :ref="colorPickerPopoverRef" :target="'popover-font-color-'+uuid" placement="bottom">
						
							<color-picker
								v-model="textColor"
								class="dvs-mb-2"
								@cancel="closeFontColorPopover()"
							/>
							<div class="row no-gutters mt-2">
								<div class="col-auto pr-1">
									<button
										class="btn-block btn btn-secondary"
										@click="closeFontColorPopover()"
									>
										<font-awesome-icon :icon="['fal', 'times']" /> {{ $t('editor.cancel') }}
									</button>
								</div>
								<div class="col pl-1">
									<button
										class="btn-block btn btn-primary"
										@click="applyTextColor(commands)"
									>
										<font-awesome-icon :icon="['fal', 'save']" /> {{ $t('editor.apply') }}
									</button>
								</div>
							</div>
						
					</b-popover>

					<!-- TextAlign -->
					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.alignment({ textAlign: 'left' }) }"
						@click="commands.alignment({ textAlign: 'left' })"
						>
						<font-awesome-icon :icon="['fas', 'align-left']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.alignment({ textAlign: 'center' }) }"
						@click="commands.alignment({ textAlign: 'center' })"
						>
						<font-awesome-icon :icon="['fas', 'align-center']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.alignment({ textAlign: 'center' }) }"
						@click="commands.alignment({ textAlign: 'right' })"
						>
						<font-awesome-icon :icon="['fas', 'align-right']" />
					</button>

					<button
						class="menubar__button"
						:class="{ 'is-active': isActive.alignment({ textAlign: 'center' }) }"
						@click="commands.alignment({ textAlign: 'justify' })"
						>
						<font-awesome-icon :icon="['fas', 'align-justify']" />
					</button>

					<!-- Tableau -->
					<button class="menubar__button" v-b-toggle="'table-tools'+uuid"  variant="primary">
						<font-awesome-icon :icon="['far', 'table']" />
					</button>

					<b-collapse class="col-12" :id="'table-tools'+uuid">
						<button
							class="menubar__button"
							@click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
						>
							<font-awesome-icon :icon="['cf', 'addTable']" />
						</button>
						<button
							class="menubar__button"
							@click="commands.deleteTable"
						>
							<font-awesome-icon :icon="['cf', 'deleteTable']" />
						</button>
						<button
							class="menubar__button"
							@click="commands.addColumnBefore"
						>
							<font-awesome-icon :icon="['cf', 'addColBefore']" />
						</button>
						<button
							class="menubar__button"
							@click="commands.addColumnAfter"
						>
							<font-awesome-icon :icon="['cf', 'addColAfter']" />
						</button>
						<button
							class="menubar__button"
							@click="commands.deleteColumn"
						>
							<font-awesome-icon :icon="['cf', 'deleteCol']" />
						</button>
						<button
							class="menubar__button"
							@click="commands.addRowBefore"
						>
							<font-awesome-icon :icon="['cf', 'addRowBefore']" />
						</button>
						<button
							class="menubar__button"
							@click="commands.addRowAfter"
						>
							<font-awesome-icon :icon="['cf', 'addRowAfter']" />
						</button>
						<button
							class="menubar__button"
							@click="commands.deleteRow"
						>
							<font-awesome-icon :icon="['cf', 'deleteRow']" />
						</button>
						<button
							class="menubar__button"
							@click="commands.toggleCellMerge"
						>
							<font-awesome-icon :icon="['cf', 'combineCell']" />
						</button>
					</b-collapse>

					<!-- Raw Mode -->
					<button
						class="menubar__button"
						:class="{ 'is-active': rawMode }"
						@click="rawMode = !rawMode"
						>
						<font-awesome-icon :icon="['fas', 'code']" />
					</button>
				</div>
			</editor-menu-bar>

			<editor-content v-if="!rawMode" class="editor__content" :editor="editor" v-model="content"></editor-content>
			<b-form-textarea v-else class="editor__content" v-model="localContent"></b-form-textarea>
		</div>

		<div class="col-4 suggestion-list" v-if="showSuggestions" ref="suggestions">
			<template v-if="hasResults">
				<div
					v-for="(elem, index) in filteredElems"
					:key="elem.id"
					:class="{
						'is-selected': navigatedElemIndex === index,
						'suggestion-list__item': true,
						'border-bottom': true
					}"
					@click="selectElem(elem)"
				>
					{{ elem.name }}
				</div>
			</template>
			<div v-else class="suggestion-list__item is-empty">
				{{ $t('editor.no_suggestion_found') }}
			</div>
		</div>
	</div>
</template>

<script>
import Common from '@/assets/js/common.js'
import FileMixin from '@/mixins/File'
import { Chrome as ColorPicker } from 'vue-color'
import _cloneDeep from 'lodash/cloneDeep'

// https://github.com/ueberdosis/tiptap
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import Fuse from 'fuse.js'
import tippy, { sticky } from 'tippy.js'

import {
	CodeBlock,
	HardBreak,
	Heading,
	OrderedList,
	BulletList,
	ListItem,
	TodoItem,
	TodoList,
	Bold,
	Code,
	Italic,
	Image,
	Link,
	Strike,
	Underline,
	History,
	HorizontalRule,
	Placeholder,
	Mention,
	Table,
	TableHeader,
	TableCell,
	TableRow,
} from 'tiptap-extensions'

import ContentBlock from 'GroomyRoot/assets/js/editor/nodes/ContentBlock'
import TextAlign from 'GroomyRoot/assets/js/editor/nodes/TextAlign'
import TextColor from 'GroomyRoot/assets/js/editor/nodes/TextColor'
import FontSize from 'GroomyRoot/assets/js/editor/nodes/FontSize'

export default {
	components: {
		EditorContent,
		EditorMenuBar,
		'color-picker': ColorPicker
	},
	name:'e-editor',
	mixins: [FileMixin],
	data() {
		return {
			uuid: Common.getNegativeId(),
			input_text: '',
			editor: null,
			query: null,
			popup: null,
			suggestionRange: null,
			filteredElems: [],
			navigatedElemIndex: 0,
			insertMention: () => {},
			focusedBlockIds: [],
			imageCommand: null,
			textSize: 16,
			showTableOptions: false,
			rawMode: false,
			
			/* TextColor */
			showTextColorPicker: false,
			textColor: '#000000',

			/* Raw Mode */
			localContent: null,
			prevContent: null
		}
	},
	props: {
		placeholder: {
			type: String,
			default: () => ('')
		},
		content: {
			type: String,
			default: () => ('')
		},
		identifier: {
			type: [String, Number],
			default: () => ('')
		},
		variables: {
			type: Array,
			default: () => []
		},
		blocks: {
			type: Array,
			default: () => []
		},
		disabled: {
			type: Boolean,
			default: () => ( false )
		}
	},
	model: {
		prop: 'checked',
		event: 'change'
	},
	mounted () {
		this.localContent = this.content
		this.initEditor()
	},
	beforeDestroy() {
		this.editor.destroy()
	},
	computed: {
		colorPickerPopoverRef() {
			return 'fontColorPopover_'+this.uuid
		},
		textSizePopoverRef() {
			return 'fontSizePopover_'+this.uuid
		},

		hasResults() {
			return this.filteredElems.length
		},

		showSuggestions() {
		   return this.query || this.hasResults
		},

		/**
		 * Affiche la liste des blocs affichables à l'endroit actuel du curseur
		 */
		flattenBlocks() {
			return this.blocks
				.concat(this.getDirectChildren(this.blocks, this.focusedBlockIds))
		},

		rawLocalContentMutated() {
			return this.prevContent != this.localContent
		}
	},
	watch: {
		'editor.state.selection.anchor'(val) {
			this.updateFocusedBlocks()
		},
		'rawMode' (val) {
			// Si le rawMode est désactivé et qu'il y a eu des changements apportés au content, on reconstruit l'Editor avec le nouveau content
			if(!val) {
				if(this.rawLocalContentMutated) {
					this.initEditor(this.localContent)	
					this.updateParentContent(this.localContent)		
				}
			}
			// Sinon on backup l'état actuel du content
			else {
				const new_content = this.editor.getHTML()
				this.updateParentContent(new_content)
				this.localContent = new_content
				this.saveContentState()
			}
		}
	},
	methods: {
		initEditor(customContent=null) {
			if(this.editor) this.editor.destroy()

			this.editor = new Editor({
				editable: !this.disabled,
				extensions: [
					new HardBreak(),
					new Heading({ levels: [1, 2, 3] }),
					new BulletList(),
					new OrderedList(),
					new ListItem(),
					new TodoItem(),
					new TodoList(),
					new Bold(),
					new Code(),
					new Image(),
					new Italic(),
					new Link(),
					new Strike(),
					new Underline(),
					new History(),
					new HorizontalRule(),
					new ContentBlock(),
					new Placeholder({
						emptyEditorClass: 'is-editor-empty',
						emptyNodeClass: 'is-empty',
						emptyNodeText: this.placeholder,
						showOnlyWhenEditable: true,
						showOnlyCurrent: true,
					}),
					new Table({
						resizable: true
					}),
					new TableHeader(),
					new TableCell(),
					new TableRow(),
					new TextAlign(),
					new TextColor(),
					new FontSize({
						unit: 'px'
					}),
					new Mention({
						// a list of all suggested items
						items: () => {
							// Récupération des variables des blocs focused pour les ajouter aux propositions
							const blocksVariables = this.findFocusedBlocksVariables(this.blocks)

							/**
							 * Fix d'un bug du plugin de suggestions
							 * @see https://github.com/ueberdosis/tiptap/issues/932#issuecomment-779241610
							 */
							const variables = this.variables.concat(blocksVariables)
							variables.forEach(variable => {
								variable.name = variable.name + ' '
							})
							return variables
						},
						// is called when a suggestion starts
						onEnter: ({
							items, query, range, command, virtualNode,
						}) => {
							this.query = query
							this.filteredElems = items
							this.filteredElems  = this.filteredElems.sort(this.SortArray);
							this.suggestionRange = range
							this.renderPopup(virtualNode)
							// we save the command for inserting a selected mention
							// this allows us to call it inside of our custom popup
							// via keyboard navigation and on click
							this.insertMention = command
						},
						// is called when a suggestion has changed
						onChange: ({
							items, query, range, virtualNode,
						}) => {
							this.query = query
							this.filteredElems = items
							this.filteredElems  = this.filteredElems.sort(this.SortArray);
							this.suggestionRange = range
							this.navigatedElemIndex = 0
							this.renderPopup(virtualNode)
						},
						// is called when a suggestion is cancelled
						onExit: () => {
							// reset all saved values
							this.query = null
							this.filteredElems = []
							this.suggestionRange = null
							this.navigatedElemIndex = 0
							this.destroyPopup()
						},
						// is called on every keyDown event while a suggestion is active
						onKeyDown: ({ event }) => {
							if (event.key === 'ArrowUp') {
								this.upHandler()
								return true
							}
							if (event.key === 'ArrowDown') {
								this.downHandler()
								return true
							}
							if (event.key === 'Enter') {
								this.enterHandler()
								return true
							}
							return false
						},
						// is called when a suggestion has changed
						// this function is optional because there is basic filtering built-in
						// you can overwrite it if you prefer your own filtering
						// in this example we use fuse.js with support for fuzzy search
						onFilter: (items, query) => {
							if (!query) {
								return items
							}
							const fuse = new Fuse(items, {
								threshold: 0.2,
								keys: ['name'],
							})
							return fuse.search(query).map(item => item.item)
						},
					}),
				],
				content: customContent || this.content,
				onUpdate: ({ getHTML }) => {
					let width = 0
					document.getElementsByClassName("ProseMirror").forEach(element => {
						if(element.offsetWidth > 0) {
							width = element.offsetWidth
						}
					})

					this.updateParentContent(getHTML(), width)
				}
			})
		},
		//tri par ordre alphabétique des filteredElems
		SortArray(x, y){
			if (x.name < y.name) {return -1;}
			if (x.name > y.name) {return 1;}
			return 0;
		},
		upHandler() {
			this.navigatedElemIndex = ((this.navigatedElemIndex + this.filteredElems.length) - 1) % this.filteredElems.length
		},
		// navigate to the next item
		// if it's the last item, navigate to the first one
		downHandler() {
			this.navigatedElemIndex = (this.navigatedElemIndex + 1) % this.filteredElems.length
		},
		enterHandler() {
			const elem = this.filteredElems[this.navigatedElemIndex]
			if (elem) {
				this.selectElem(elem)
			}
		},
		// we have to replace our suggestion text with a mention
		// so it's important to pass also the position of your suggestion text
		selectElem(elem) {
			this.insertMention({
				range: this.suggestionRange,
				attrs: {
					id: elem.id,
					label: elem.name,
				},
			})
			this.editor.focus()
		},
		// renders a popup with suggestions
		// tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
		renderPopup(node) {
			if (this.popup) {
				return
			}

			// ref: https://atomiks.github.io/tippyjs/v6/all-props/
			this.popup = tippy('.page', {
				getReferenceClientRect: node.getBoundingClientRect,
				appendTo: () => document.body,
				interactive: true,
				sticky: true, // make sure position of tippy is updated when content changes
				plugins: [sticky],
				content: this.$refs.suggestions,
				trigger: 'mouseenter', // manual
				showOnCreate: true,
				theme: 'dark',
				placement: 'left-end',
				inertia: true,
				duration: [400, 200],
			})
		},
		destroyPopup() {
			if (this.popup.length > 0) {
				this.popup[0].destroy()
				this.popup = null
			}
		},
		updateFocusedBlocks() {
			const focusedNode = this.editor.view.domAtPos(this.editor.state.selection.anchor).node
			this.focusedBlockIds = this.findFocusedBlockIds(focusedNode)
		},
		findFocusedBlockIds(node) {
			let ids = []

			if (node.classList && node.classList.contains('editor__content')) {
				return ids
			}

			if (node.hasAttribute && node.hasAttribute('data-block-name')) {
				ids.push(
					node.getAttribute('data-block-name')
				)
			}

			if (node.parentNode) {
				ids = ids.concat(this.findFocusedBlockIds(node.parentNode))
			}

			return ids
		},
		findFocusedBlocksVariables(blocks) {
			let blocksVariables = []
			blocks.forEach(block => {
				if (this.focusedBlockIds.indexOf(block.id) !== -1) {
					blocksVariables = blocksVariables
						.concat(block.variables)
						.concat(this.findFocusedBlocksVariables(block.children))
				}
			})
			return blocksVariables
		},
		/**
		 * Retourne les enfants au premier niveau des blocs dans blocksIds
		 */
		getDirectChildren(blocks, blocksIds) {
			let children = []
			blocks.forEach(block => {
				// Si c'est un des blocs recherchés on prend ses enfants
				if (blocksIds.indexOf(block.id) !== -1) {
					children = children.concat(block.children)
				}

				// On continue la recherche dans les enfants
				children = children.concat(
					this.getDirectChildren(block.children, blocksIds)
				)
			})
			return children
		},
		insertVariable() {
			const transaction = this.editor.state.tr.insertText('@')
			this.editor.view.dispatch(transaction)
			this.editor.focus()
		},
		showFontColorPopover() {
			this.$refs[this.colorPickerPopoverRef].$emit('open')
		},
		closeFontColorPopover() {
			this.$refs[this.colorPickerPopoverRef].$emit('close')
		},
		applyTextColor(commands) {
			const { r, g, b, a } = this.textColor.rgba
			commands.textcolor({ color: `rgba(${r},${g},${b},${a})` })
			this.showTextColorPicker = false
			this.closeFontColorPopover()
		},
		closeFontSizePopover() {
			this.$refs[this.textSizePopoverRef].$emit('close')
		},
		applyTextSize(commands) {
			commands.fontSize({ fontSize: this.textSize })
			this.closeFontSizePopover()
		},
		showImageBrowser(command) {
			this.imageCommand = command
			this.$refs.imageInput.click()
		},
		async onImageSelection(evt) {
			try {
				const file = evt.target.files[0]
				if (!file) {
					return
				}
				const base64 = await this.fileToBase64(file)
				this.imageCommand({ src: base64 })
			}
			catch(e) {
				console.error(e)
				this.failureToast('error.LEP')
			}
			evt.target.value = null
		},
		/* Pour envoyer les modifs du content au parent */
		updateParentContent(val, width = 0) {
			this.$emit('change', { key: this.identifier, val: val, width: width, type: 'EEditor' })
		},
		/* Uniquement pour avoir un état précédent du localContent */
		saveContentState() {
			this.prevContent = this.localContent
		}
	}
}
</script>