import Rollbar from 'vue-rollbar'
import Vue from 'vue'

if (process.env.VUE_APP_ROLLBAR_ENABLED) {
    const licenceKey = localStorage.getItem('licence_key')
    const userId = localStorage.getItem('user_id')

    Vue.use(Rollbar, {
        accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: true,
        environment: process.env.VUE_APP_VERSION || 'local',
		checkIgnore(isUncaught, args) {
			const msg = args[0]
			return msg.startsWith('Request failed with status code')
				|| msg.includes('The database connection is closing')
				|| msg.includes('Database has been closed')
		},
        payload: {
            client: {
                javascript: {
                    code_version: process.env.VUE_APP_BUILD_VERSION,
                    source_map_enabled: true,
                    guess_uncaught_frames: true
                }
            },
            person: {
                id: `${userId}|${licenceKey}`,
            }
        }
    })
}
