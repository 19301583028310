import Vue from 'vue'
import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n'
import GroomyStore from '@/store/index'
import FILES from '@/assets/js/i18n-files'

const AVAILABLE_LANGS = ['fr', 'en']

export function getNavigatorLang() {
	let navigatorLang = (navigator.language || 'fr').substr(0,2)
	if (AVAILABLE_LANGS.indexOf(navigatorLang) === -1) {
		navigatorLang = 'fr'
	}

	return navigatorLang
}

export function loadI18nPlugin(defaultLang='fr') {
	GroomyStore.commit('user/setLang', defaultLang)

	Vue.use(vuexI18n.plugin, store, {
		locale: defaultLang,
		fallbackLocale: 'fr',
		moduleName: 'i18n'
	})

	// add translations directly to the application
	AVAILABLE_LANGS.forEach(code => {
		if (code === defaultLang) {
			loadLocale(code)
			Vue.i18n.set(code);
		}
		else {
			Vue.i18n.add(code, {})
		}
	})
}

export function loadLocale(langCode) {
	const messages = require(`@/assets/lang/${langCode}/index.json`)

	FILES.forEach(file => {
		messages[file.replace('/', '.')] = require(`@/assets/lang/${langCode}/${file}.json`)
	})

	Vue.i18n.add(langCode, messages)
}


Vue.use(Vuex)
const store = new Vuex.Store()

export default store