export default {
    data: () => ({
        windowWidth: window.innerWidth
    }),
    created() {
        window.addEventListener('resize', this.onWindowResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize)
    },
    computed: {
        isXs() {
            return this.windowWidth <= 575
        },
        isSm() {
            return this.windowWidth > 575 && this.windowWidth <= 767
        },
        isMd() {
            return this.windowWidth > 768 && this.windowWidth <= 991
        },
        isLg() {
            return this.windowWidth > 992 && this.windowWidth <= 1199
        },
        isXl() {
            return this.windowWidth > 1199
        },
        isBelowMd() {
            return this.isXs || this.isSm
        }
    },
    methods: {
        onWindowResize() {
            this.windowWidth = window.innerWidth
            this.onResize(this.windowWidth)
        },
        onResize(width) {

        }
    }
}