'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'cf';
var iconName = 'addTable';
var width = 24;
var height = 24;
var ligatures = [];
var unicode = 'e000';
var svgPathData = ['M24,7.8V5c0-2.8-2.2-5-5-5H5C2.2,0,0,2.2,0,5v14c0,2.8,2.2,5,5,5h9.1c-0.6-0.6-1.1-1.3-1.4-2H9v-5h3.3c0.2-0.7,0.5-1.4,1-2 H9V9h6v4.3c0.6-0.4,1.3-0.7,2-1V9h5v3.7c0.7,0.4,1.4,0.8,2,1.4V8.2c0-0.1,0-0.1,0-0.2S24,7.9,24,7.8z M7,22H5c-1.7,0-3-1.3-3-3v-2h5 V22z M7,15H2V9h5V15z M7,7H2V5c0-1.7,1.3-3,3-3h2V7z M15,7H9V2h6V7z M22,7h-5V2h2c1.7,0,3,1.3,3,3V7z M19,14c-2.8,0-5,2.2-5,5 s2.2,5,5,5s5-2.2,5-5C24,16.2,21.8,14,19,14z M21.5,19.9h-1.6v1.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9v-1.6h-1.6 c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h1.6v-1.6c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v1.6h1.6c0.5,0,0.9,0.4,0.9,0.9 S22,19.9,21.5,19.9z'];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.cfAddTable = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
