<template>
	<div v-if="show_menu">
		<nav>
            <NavMobile v-if="isBelowMd" :from-ca="true" />
            <Nav v-else/>
	    </nav>
    </div>
</template>


<script type="text/javascript">
import User from '@/mixins/User.js'
import Navigation from '@/mixins/Navigation.js'
import Window from '@/mixins/Window.js'
import Constants from '../../config.local.js'

export default {
	mixins: [User, Navigation, Window],
	components: {
		Nav: () => import('GroomyRoot/components/Header/Nav'),
		NavMobile: () => import('GroomyRoot/components/Header/NavMobile'),
	},
    data() {
        return {
            verified_user : null,
            blacklist: [ 
				"PaxExpress",
				"Home",
                "CompteCreation",
				"EmailValidation",
                "askResetPassword",
                "Loading"
            ],
            events_tab: {
                'Header::displayToast': this.displayToast,
                'verified_user': this.onUserVerified
            }
        }
    },
    computed: {
        show_menu() {
            const route_name = this.$route.name

            if(!route_name) return false
            return !this.blacklist.includes(route_name)
        }
    },
    methods: {
        async logout() {
            await this.delogUser()
        },

        displayToast(params) {
            let paramsToast = {}

            if(params.title) {
                paramsToast.title = params.title
            }
            paramsToast.variant = params.type
			paramsToast.solid = false
			paramsToast.toaster = params.toaster || 'b-toaster-top-right'

            this.$bvToast.toast(params.body, paramsToast)
        },

        onUserVerified(payLoad) {
            this.verified_user = payLoad
        },

        async reload() {
            let licence_key = this.getConfig("licence_key")
            let token = this.getConfig("token")
            await this.rmConfig();
            this.setConfig("licence_key", licence_key)
            this.setConfig("token", token)
            await this.loadConfig()
            location.reload()
        },

        sendSampleNotification() {
            const url = `${Constants.BASE_API_URL}/api/notifications/sample/${Constants.USER_LICENCE_KEY}/${this.getConfig('user_id')}`
            this.$request.request_post_api('Notification::sendSample', url)
        }
    }
}
</script>
