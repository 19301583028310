import RootRequest from 'GroomyRoot/assets/js/requests.js'
import Vue from 'vue'
import { EventBus } from 'EventBus'

export default class Request extends RootRequest {
    
    async after_request(caller, url, method, response, _) {
        return response
    }
    catch_errors (err) {
        if (err.response) {
            if(err.response.status === 401) {
                if (err.response.data && err.response.data.error !== 'Invalid credentials') {
                    // EventBus.$emit('App::forceDelogUser')
                    let params = {
                        title: Vue.i18n.translate('global.information'),
                        body: Vue.i18n.translate('toast.contact_equideclic_evolution'),
                        type: 'info'
                    }

                    if(err.response.data == "Vous n'avez plus assez de jetons pour effectuer cette requête") {
                        params.body = err.response.data 
                    }

                    EventBus.$emit('Header::displayToast', params)
                    EventBus.$emit('App::forceDelogUser')
                }
            }
            else if (err.response.status === 503) {
                EventBus.$emit('App::failureToast', 'error.TUN')
            } 
            else if (err.response.status == 406) {
                EventBus.$emit('App::failureToast', err.response.data.message)

                navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`).then(registration => {
                    registration.update();
                    EventBus.$emit('SW::updateFound')
                    EventBus.$on('SW::updated', () => {
                        window.location.reload()
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 5000);
                })
            }
            else if (err.response.status == 403) {
                // si j'ai une 403 j'affiche un toast d'erreur et je redirige après 1s (le temps d'afficher le toast)
                EventBus.$emit('App::failureToast', err.response.data.message)
                setTimeout(() => {
                    window.location.replace("/");
                }, 1000)
            }
            else if (err.response.data) {
                const blacklist = ['CLDNHACA']

                if (err.response.data.type === 'ApiException' && blacklist.indexOf(err.response.data.code_retour) === -1) {
                    EventBus.$emit('App::failureToast', err.response.data.message)
                }
            } 
        }

        throw err
    }
}
