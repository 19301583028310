'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'cf';
var iconName = 'echographie';
var width = 6.9;
var height = 16;
var ligatures = [];
var unicode = 'e000';
var svgPathData = ['M0,0V16H6.58l.369-2.7H3.082V8.933h3.3V6.227h-3.3V2.721H6.556V0Z" transform="translate(0 0)'];

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData,
  ]};

exports.cfEchographie = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
